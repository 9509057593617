//
//
//
//
//
//

// @ is an alias to /src
import SponsorTeamPage from '@/components/SponsorTeamPage.vue';
import store from '../store/index';

export default {
  name: 'SponsorTeam',
  components: {
    SponsorTeamPage
  },
  created () {
      store.set("APP_TOOLBAR_TITLE","ตารางทีมงานแนะนำ");
  }    
}
