//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//import HelloWorld from './components/HelloWorld.vue'
import BaseTimer from "@/components/BaseTimer";
import { get } from 'vuex-pathify';
import store from '@/store/index';
import json from '@/assets/eBonusOrdersPermission.json'
export default {
  name: 'LayoutDefault',

  components: {
    BaseTimer
    //HelloWorld
  },

  data () {
    return {
      ui_size: "",
      leftDrawerOpen: false,
      seamless: false,
      confirm_logout: false,
      confirm_visible: false,
      members: json,
      /*
      members: [
        { mcode : "000000000" },
        { mcode : "000000001" },
        { mcode : "000000002" }
      ]
      */
    }
  },
  methods: {
    setUISize() {
        //if (this.$q.screen.width > 1023) { 
        if (this.$q.screen.width > 800) {
          console.log('screen large');
          this.ui_size = "D";
        }
        else {
          console.log('screen small');
          this.ui_size = "M";
        }
    },
    show_confirm_logout_dialog() {
      this.confirm_logout = true;
    },
    saveState () {
      const serializedState = JSON.stringify(this.$store.state)
      sessionStorage.setItem('appGDMemberState', serializedState)
    },
    logout() {
      store.set("SESSION_MCODE_LOGIN", "");
      store.set("OFFICE_CONTROL_USER", "");
      this.saveState();
      this.seamless = false;
      this.$router.push({ name:"Root"});
      if (this.$q.fullscreen.isActive) {
        this.$q.fullscreen.toggle();
      }
    },
    timeup_process() {
      /*
      this.seamless = false;
      this.$router.push({ name:"Signin"});
      */
      let self = this;
      setTimeout( function() { 
        self.seamless = false;
        self.$router.push({ name:"Signin"});
      }, 800);
    },
    checkShutdownStatus() {
        let self = this;
        let currentdate = new Date(); 
        let datetime = "Last Sync: " + currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/" 
                + currentdate.getFullYear() + " @ "  
                + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
                + currentdate.getSeconds();
        //alert("URL:" + self.urlShutdownStatus+ 'SystemStatus.xml' + datetime);
        self.axios({
          method: 'get',
          url: self.urlShutdownStatus + 'SystemStatus.xml?rand'+ datetime
        })
        .then(function (response) {
            //console.log("result :" + response.data);
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let shutdown_value = responseDoc.getElementsByTagName('shutdown_value')[0].textContent;
            //alert("shutdown value : " + shutdown_value + "self.session_mcode : " + self.session_mcode);
            if (shutdown_value == "1" && !self.seamless && self.session_mcode != "") {
                store.set("APP_SHUTDOWN_VALUE", shutdown_value);
                self.seamless = true;
            } else {
              if (shutdown_value == "" || shutdown_value == "0") {
                  store.set("APP_SHUTDOWN_VALUE", shutdown_value);
                  self.seamless = false;
              }
            }
            self.checkShutdownStatusTimer();
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("init Error :" + error);
            self.$q.loading.hide();
            self.status_text = "Offline";
            self.status_color = "red-7";
        }); 
    },
    checkShutdownStatusTimer() {
        let self = this;
        setTimeout( function() { 
            //if (self.session_mcode != "") {
               self.checkShutdownStatus();
            //}
        }, 24000);
    },
    setFullScreen() {
        if (!this.$q.fullscreen.isActive) { 
            this.$q.fullscreen.request()
            .then(() => { // v1.5.0+
              //self.$q.fullscreen.toggle();
              // success!
            })
            .catch(err => { // v1.5.0+
              console.log(err);
              // oh, no!!!
            });     
        }       
    },

  },
  mounted: function () {
    this.checkShutdownStatus();
  },
  computed: {
    contentSize () {
      return this.moreContent ? 150 : 5
    },
    appToolbarClass: get('APP_TOOLBAR_CLASS'),
    appToolbarTitle: get('APP_TOOLBAR_TITLE'),
    officeControlUser: get('OFFICE_CONTROL_USER'),
    session_mcode: get('SESSION_MCODE_LOGIN'),
    session_date: get('SESSION_DATE'),
    urlShutdownStatus: get('URL_SHUTDOWN_STATUS'),
    eBonusOrderPermission () {
      let someResult;
      /*
      this.members.forEach((member) => {
        alert("mcode : " + member.mcode)
      });
      */
      someResult = this.members.some((member) => {
        return member.mcode == this.session_mcode
      })
      //alert("someResult : " + someResult.toString());
      return someResult;
    }
  },
  watch: {
    "$q.screen.width"() {
      this.setUISize()
    },
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
    },
    
    "$q.appVisible": function (val) {
      console.log(val ? 'App became visible' : 'App went in the background');
      console.log("current path:" + this.$route.path);
      if (!(this.$route.path == "/" || this.$route.path == "/signin")) {
        if (this.officeControlUser=='') {
          this.confirm_visible = true;
        }
      }
    }
    
  },
  created () {
    this.setUISize();
  }
}
