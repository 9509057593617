import { render, staticRenderFns } from "./DistrictDialog.vue?vue&type=template&id=0a6bc1f0&"
import script from "./DistrictDialog.vue?vue&type=script&lang=js&"
export * from "./DistrictDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QSeparator,QSelect,QInput,QCardActions,QBtn,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QSeparator,QSelect,QInput,QCardActions,QBtn})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
