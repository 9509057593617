//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify';

export default {
  name: 'MemberListDialog',
  props: ["show_status"],
  data () {
    return {
      loading: false,
      total_record: 0,
      total_page: 1,
      total_page_suffix: '0',
      findBy:  {label: 'รหัสสมาชิก', value: 'mcode' },
      options: [
        {label: 'รหัสสมาชิก', value: 'mcode' },
        {label: 'USERNAME', value: 'name_f' },
        {label: 'ชื่อสมาชิก', value: 'name_t' },
        {label: 'วันที่สมัคร', value: 'mdate' },
        {label: 'เลขบัตรประชาชน', value: 'id_card' }
      ],
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      findWord: '',
      current_row: 1,
      min_row: 0,
      max_row: 0,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum' },
        { name: 'mcode', align: 'center', label: 'รหัสสมาชิก', field: 'mcode'},
        { name: 'name_t', align: 'left', label: 'ชื่อสมาชิก', field: 'name_t'},
        { name: 'mdate',  align: 'center', label: 'วันที่สมัคร', field: 'mdate' }
      ],
      /* 
      customDialogModel: false,
      name: ''
      */
      fixed: false
    }
  },
  methods: {
    previous_page: function() {
      if (parseInt(this.pageID) > 1) {
        this.pageID = parseInt(this.pageID) - 1;
      }
    },
    next_page: function() {
      if (parseInt(this.pageID) < parseInt(this.total_page)) {
        this.pageID = parseInt(this.pageID) + 1;
      }
    },
    requestRowClick: function(selected_mcode, selected_row) {
        this.current_row = selected_row.rownum;
        //console.log("row click :" + selected_mcode);
        if (this.selected.mcode !== selected_mcode) {
          this.selected.splice(0,1);
          this.selected.push(selected_row);
          this.$emit("onSeleted", selected_row);
        }
    },
    fetchDataList: function () {
      let self = this;
      self.loading = true;
      const params = new URLSearchParams();
      params.append('pageSize', this.rowsPerFetch);
      params.append('pageID', this.pageID);
      params.append('findBy', this.findBy.value);
      params.append('findWord', this.findWord);
      params.append('mcode', this.session_mcode);
      self.axios({
        method: 'post',
        headers: {
          'Authorization': self.jwt_token
        },        
        timeout: 60 * 4 * 1000, // Let's say you want to wait at least 4 mins
        url: self.url_api + 'MemberClient/Member.List.JSON.aspx',
        data: params
      })
      .then(function (response) {
        //console.log("response data");
        //console.log("result :" + response.data);
        let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
       
        self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
        self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
        self.current_row = self.selected[0].rownum;
        self.min_row = self.selected[0].rownum;
        self.max_row = self.min_row + self.rowsPerFetch - 1;
        self.total_record = responseDoc.getElementsByTagName('recordcount')[0].textContent;
        self.total_page = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
        self.total_page_suffix = "/" + self.total_page;
        self.loading = false;
       
      })
      .catch(function (error) {
          console.log("Error :" + error);
          self.loading = false;
      });
    }, 
    onHide() {
      //console.log("onHide");
      this.$emit("onHide", "");
      //this.$parent.scode_dialog_show = false;
    }
    /*
    ,
    // when props.ok() gets called
    onOk (data) { },

    // when props.cancel() gets called
    onCancel () { },

    // when we show it to the user
    onShow () { },

    // when it gets hidden
    onHide () { },

    // custom handler
    async choose (okFn, hero) {
      if (this.name.length === 0) {
        this.$q.dialog({
          title: 'Please specify your name!',
          message: `Can't buy tickets without knowing your name.`
        })
      }
      else {
        await okFn()
        this.$q.notify(`Ok ${this.name}, going with ${hero}`)
      }
    }
    */
  },
  mounted () {
      //let self = this;
      //console.log('Center Dialog mounted.');
      this.fetchDataList();
      /*
      window.addEventListener("keyup", e => {
        switch (e.keyCode) {
          case 38:
            //alert("up" + e.keyCode + "current row:" + this.current_row.toString());
            //alert(parseInt(this.current_row)-1);
            if (parseInt(this.current_row) > parseInt(this.min_row)) {
              alert(parseInt(this.current_row)-1);
              this.current_row = parseInt(this.current_row) - 1
              this.selected.splice(0,1);
              this.selected.push(this.datalist[parseInt(this.current_row)-1]);
            }
            break;
          case 40:
            //alert("down" + e.keyCode + "current row:" + this.current_row.toString());
            //alert(parseInt(this.current_row)+1);
            if (parseInt(this.current_row) < parseInt(this.max_row)) {
              alert(parseInt(this.current_row)+1);
              this.current_row = parseInt(this.current_row) + 1;
              this.selected.splice(0,1);
              this.selected.push(this.datalist[parseInt(this.current_row)-1]);
            }
            break;
        }
      });
      */
      //this.writeConsole();

      /*
      const self = this;
      console.log('component API URL:' + self.url_api + 'Member/Member.View.JSON.aspx');

      //this.$fetchDataList;
      //this.alertBox();
      */
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
       if (this.show_status) {
        this.selected.splice(0,1);
      }
    },
    pageID: function () {
      this.fetchDataList();
    }
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
  }
 
}
