//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { get } from 'vuex-pathify'
import store from '../store/index'

export default {
  components: {
  },
  data () {
    return {
      loading: false,
      query:"1",
      total_record: 0,
      total_page: 1,
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'course_code', align: 'center', label: 'รหัส', field: 'course_code'},
        { name: 'course_title', align: 'left', label: 'รายละเอียด', field: 'course_title'},
        { name: 'course_date', align: 'center', label: 'วันที่จัด', field: 'course_date' },
        { name: 'location', align: 'left', label: 'สถานที่', field: 'location' }
      ],
    }
  },
  methods: {

      accept_seminar: function(seminar) {
        //console.log(seminar);
        this.$q.dialog({
          title: "ยืนยันการเข้าร่วมสัมมนา",
          message: " รหัส : <b>" + seminar.course_code + "</b></br>" +
                  " รายละเอียด : <b>" + seminar.course_title + "</b></br>" + 
                  " วันที่จัด : <b>" + seminar.course_date + "</b></br>" + 
                  " สถานที่ : <b>" + seminar.location + "</b>",
          persistent: true,
          html: true,
          class: "app-font",
          style: "background-color:#E1F3E5; color:#000000;",
          ok: {
            label: "ตกลง",
            color: 'positive'
          },
          cancel: {
            label: "ยกเลิก"
          },
        }).onOk(() => {
          this.post_confirm_seminar(seminar.course_code, "Y");
          // console.log('>>>> OK')
        }).onOk(() => {
          // console.log('>>>> second OK catcher')
        }).onCancel(() => {
          // console.log('>>>> Cancel')
        }).onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        })
      },
      reject_seminar: function(seminar) {
        //console.log(seminar);
        this.$q.dialog({
          title: "ไม่สามารถเข้าร่วมสัมมนาได้",
          message: " รหัส : <b>" + seminar.course_code + "</b></br>" +
                  " รายละเอียด : <b>" + seminar.course_title + "</b></br>" + 
                  " วันที่จัด : <b>" + seminar.course_date + "</b></br>" + 
                  " สถานที่ : <b>" + seminar.location + "</b>",
          persistent: true,
          html: true,
          class: "app-font",
          style: "background-color:#FFD3D3; color:#000000;",
          ok: {
            label: "ตกลง",
            color: 'positive'
          },
          cancel: {
            label: "ยกเลิก"
          },
        }).onOk(() => {
          this.post_confirm_seminar(seminar.course_code, "N");
          // console.log('>>>> OK')
        }).onOk(() => {
          // console.log('>>>> second OK catcher')
        }).onCancel(() => {
          // console.log('>>>> Cancel')
        }).onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        })
      },
      post_confirm_seminar: function(course_code, status) {
          let self = this;
          const params = new URLSearchParams();
          params.append('course_code', course_code);
          params.append('status', status);
          params.append('mcode', self.session_mcode);
          self.axios({
            method: "post",
            headers: {
              'Authorization': self.jwt_token
            },
            url: self.url_api + "MemberClient/Seminar.Update.Status.JSON.aspx",
            data: params
          })
          .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let result_txt = responseDoc.getElementsByTagName('message')[0].textContent;
            self.triggerPositive(result_txt);
            self.getData();
          })
          .catch(function (error) {
            self.triggerNegative("ไม่สามารถบันทึกรายการได้");
            console.log("Error :" + error);
          });
      },
      triggerPositive (msg_text) {
        this.$q.notify({
          type: 'positive',
          progress: true,
          message: msg_text,
          classes:'app-font'
        });
      },
      triggerNegative (msg_text) {
          this.$q.notify({
            type: 'negative',
            message: msg_text,
            classes:'app-font'
          })
      },
      /*
      exportExcel: function() {
          let self = this;
          openURL(self.url_api + 'Bonus/Report.Daily.Bonus.ByPeriodDate.Excel.aspx')    
      },*/
      requestRowClick: function(selected_mcode, selected_row) {
          this.current_row = selected_row.rownum;
          //console.log("row click :" + selected_mcode);
          if (this.selected.mcode !== selected_mcode) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
            //this.$emit("onSeleted", selected_row);
          }
      },
      editMode() {
        //console.log("click");
      },
      
      getData: function () {
        var self = this;
        self.loading = true;
        const params = new URLSearchParams();
        params.append('mcode', self.session_mcode);

        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 60 * 4 * 1000, // Let's say you want to wait at least 4 mins,
          url: self.url_api + 'MemberClient/Seminar.List.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            
            /*
            console.log(responseDoc.getElementsByTagName('json')[0].textContent);
            console.log("record count " + record_count.toString());
            console.log("page count " + responseDoc.getElementsByTagName('pagecount')[0].textContent);
            */

            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
            } else {
              self.datalist = [];
              self.selected = [];
            }
            self.total_record = record_count;
            self.total_page = 1;
            self.loading = false;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  },
  mounted: function () {
      /*
      window.addEventListener("keyup", e => {
        switch (e.keyCode) {
          case 38:
            alert(e.keyCode);
            break;
          case 40:
            alert(e.keyCode);
            break;
        }    
        /*
        if (String.fromCharCode(e.keyCode)=="&") {

        } else {
            if (String.fromCharCode(e.keyCode)=="(") {

            }
        }
      });*/
  
      //var self = this;
      //console.log('API URL:' + self.url_api + 'Member/Member.View.JSON.aspx');
      //console.log('Component mounted.');
      this.$q.loading.show();
      let self = this;
      setTimeout( function() { 
        self.getData();
      }, 1000);
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN')
  },
  watch: {

  },
  created () {
      store.set("APP_TOOLBAR_TITLE","ตารางสัมมนา");
      //console.log('Component created.');
  }    
}   
