//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'

export default {
  name: "SponsorTeamDialog",
  props: ["show_status","mcode_start"],

  data () {
    return {
      init: false,
      fixed: false,
      sub_mcode_root: "",
      sponsor_team_dialog_show: false,
      mcode_current: "",
      from_level: 0,
      to_level: 999,
      loading: false,
      query:"1",
      total_record: 0,
      total_page: 1,
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'lvl', align: 'center', label: 'ระดับ', field: 'lvl'},
        { name: 'mcode', align: 'center', label: 'ชื่อสมาชิก', field: 'mcode'},
        { name: 'name_t', align: 'left', label: 'ชื่อสมาชิก', field: 'name_t'},
        { name: 'mdate', align: 'center', label: 'วันสมัคร', field: 'mdate' },
        { name: 'rank', align: 'center', label: 'ตน.', field: 'rank' },
        { name: 'sp_code', align: 'center', label: 'รหัสผู้แนะนำ', field: 'sp_code' },
        { name: 'gpvtotal', align: 'right', label: 'PV สะสม', field: 'gpvtotal'},
        { name: 'pv_l', align: 'right', label: 'PV ซ้าย', field: 'pv_l'},
        { name: 'pv_r', align: 'right', label: 'PV ขวา', field: 'pv_r'}
      ],
      position_selected: { label: "ทุกตำแหน่ง", value: "all" },
      sideList: [
        { label: "ทุกด้าน", value: "all" },
        { label: "ด้านซ้าย", value: "L" },
        { label: "ด้านขวา", value: "R" }
      ],
      side_selected: { label: "ทุกด้าน", value: "all" },
      searchText:"",
      pvteam: 0,
      pvcompleteteam: 0
    }
  },
  methods: {
      resetData: function () {
        this.from_level = 0;
        this.to_level = 999;
        this.searchText = "";
        this.position_selected = { label: "ทุกตำแหน่ง", value: "all" };
        this.side_selected = { label: "ทุกด้าน", value: "all" };
        this.getData();
      },
      onHide() {
        //console.log("onHide");
        this.$emit("onHide", "");
        this.init = false;
        this.mcode_current = "";
        this.position_selected = { label: "ทุกตำแหน่ง", value: "all" };
        this.side_selected = { label: "ทุกด้าน", value: "all" };
        this.searchText = "";
        this.pvteam = 0;
        this.pvcompleteteam = 0;
        this.total_record = 0;
        this.total_page = 1;
        this.pageID = 1;
        this.datalist = [];
        this.selected = [];
        this.from_level = 0;
        this.to_level = 999;
        //this.$parent.scode_dialog_show = false;
      },
      onShow() {
        this.mcode_current = this.mcode_start;
        if (this.mcode_start != "") {
          this.$q.loading.show();
          this.getData(); 
        }
      },
      open_sponsor_dialog(mcode) {
          this.sub_mcode_root = mcode;
          this.sponsor_team_dialog_show = true;
      },
      sponsor_team_dialog_hide () {
        this.sponsor_team_dialog_show = false;
        //console.log("hide set spcode_dialog_show = " + this.sponsor_team_dialog_show);
      },
      requestRowClick: function(selected_mcode, selected_row) {
          this.current_row = selected_row.rownum;
          //console.log("row click :" + selected_mcode);
          if (this.selected.mcode !== selected_mcode) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
            //this.$emit("onSeleted", selected_row);
          }
      },
      updateDate(e, title) {
        //console.log("update mdate to :" + e);
        switch (title) {
          case "ตั้งแต่วันที่":
            this.from_bonus_date = e;
            break;
          case "ถึงวันที่":
            this.to_bonus_date = e;
            break;  
        }
      },
      getData: function () {
        var self = this;
        if (self.init) {
          self.loading = true;
        }
        self.rowsPerFetch = 200;//self.appRowsPerFetch;
        const params = new URLSearchParams();
        params.append('mcode', self.mcode_current);
        params.append('pagesize', self.rowsPerFetch);
        params.append('pageid', self.pageID);
        params.append('findword', self.searchText);
        params.append('fromlv', self.from_level);
        params.append('tolv', self.to_level);
        params.append('pos', self.position_selected.value);
        params.append('side', self.side_selected.value);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 0,
          url: self.url_api + 'MemberClient/GetMemberSpTeam.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
            if (record_count > 0) {
              self.pvteam = parseInt(responseDoc.getElementsByTagName('pvteam')[0].textContent);
              self.pvcompleteteam = parseInt(responseDoc.getElementsByTagName('pvcompleteteam')[0].textContent);
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
              self.total_record = record_count;
              self.total_page = page_count;
              self.loading = false;
              self.init = true;
              self.$q.loading.hide();
            } else {
              self.datalist = [];
              self.selected = [];
              self.total_record = 0;
              self.total_page = 1;    
              self.pvteam = 0;
              self.pvcompleteteam = 0;    
              self.loading = false;
              self.init = true;
              self.$q.loading.hide();  
            }
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.total_record = 0;
            self.total_page = 1;
            self.pvteam = 0;
            self.pvcompleteteam = 0;        
            self.loading = false;
            self.$q.loading.hide();
        });
      }
    
  },
  mounted () {
    this.mcode_current = this.mcode_start;
  },
  watch: {
    pageID: function () {
      if (this.init) {
        this.getData();
      }
    },
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
    },
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      positionList: get('POSITION_LIST')
  },
  created () {
      //console.log('Component created.');
      this.$q.loading.show();
  }    
 
}
