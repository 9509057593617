//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { openURL } from 'quasar'
import { get } from 'vuex-pathify'
import store from '../store/index'
import MbscDatePicker from '@/components/MbscDatePicker.vue'
import EPointWithdrawDialog from '@/components/EPointWithdrawDialog.vue'

export default {
  components: {
    MbscDatePicker,
    EPointWithdrawDialog
  },
  data () {
    return {
      init: false,
      epoint_withdraw_dialog_show: false,
      from_date:"",
      to_date:"",
      loading: false,
      query:"1",
      total_record: 0,
      total_page: 1,
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 't_date', align: 'center', label: 'วันที่', field: 't_date'},
        { name: 't_time', align: 'center', label: 'เวลา', field: 't_time'},
        { name: 't_bankcode', align: 'center', label: 'ธนาคาร', field: 't_bankcode' },
        { name: 't_acc_no', align: 'center', label: 'เลขที่บัญชี', field: 't_acc_no' },
        { name: 't_remark', align: 'left', label: 'ชื่อบัญชี', field: 't_remark' },
        { name: 't_epoint', align: 'right', label: 'ยอด E-Bonus', field: 't_epoint' }
      ],
    }
  },
  methods: {
      openWithdrawDialog: function() {
        this.epoint_withdraw_dialog_show = true;
      },
      epoint_withdraw_dialog_hide () {
        this.epoint_withdraw_dialog_show = false;
        //console.log("hide set epoint_withdraw_dialog_show = " + this.epoint_withdraw_dialog_show);
      },
      exportPDF: function() {
          let self = this;
          openURL(self.url_api + '../pdf/ePoint.GetBalance.ByDate.PDF.aspx');    
      },
      requestRowClick: function(selected_mcode, selected_row) {
          this.current_row = selected_row.rownum;
          //console.log("row click :" + selected_mcode);
          if (this.selected.mcode !== selected_mcode) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
            //this.$emit("onSeleted", selected_row);
          }
      },
      /*
      fetchDateRange: function () {
        const params = new URLSearchParams();
        params.append("mcode", this.session_mcode);
        let self = this;
        self.axios({
          method: "post",
          url: self.url_api + "MemberClient/GetBonusDateRange.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let start_date = responseDoc.getElementsByTagName('startdate')[0].textContent;
          self.from_date = start_date;
          self.to_date = self.session_date;
          self.getData();
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },*/
      updateDate(e, title) {
        //console.log("update mdate to :" + e);
        switch (title) {
          case "ตั้งแต่วันที่":
            this.from_date = e;
            break;
          case "ถึงวันที่":
            this.to_date = e;
            break;  
        }
      },
      getData: function () {
         var self = this;
        if (self.init) {
          self.loading = true;
        }
        self.rowsPerFetch = self.appRowsPerFetch;
        const params = new URLSearchParams();
        params.append('pagesize', self.rowsPerFetch);
        params.append('pageid', self.pageID);
        params.append('from_date', self.from_date);
        params.append('to_date', self.to_date);
        params.append('mcode', self.session_mcode);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },  
          timeout: 0,
          url: self.url_api + 'MemberClient/ePoint.Withdraw.ByDate.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
              self.total_record = record_count;
              self.total_page = page_count;
              self.loading = false;
              self.init = true;
              self.$q.loading.hide();
            } else {
              self.datalist = [];
              self.selected = [];
              self.total_record = 0;
              self.total_page = 1;
              self.loading = false;
              self.init = true;
              self.$q.loading.hide();    
            }
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.total_record = 0;
            self.total_page = 1; 
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  },
  mounted: function () {
      //this.fetchDateRange();
      this.from_date = this.session_mdate;
      this.to_date = this.session_date;
      this.getData();
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_date: get('SESSION_DATE'),
      session_mdate: get('SESSION_MDATE'),
  },
  watch: {
    pageID: function () {
      if (this.init) {
          this.getData();
      }
    },
    from_date: function () {
      if (this.init) {
          this.getData();
      }
    },
    to_date: function () {
      if (this.init) {
          this.getData();
      }
    }
  },
  created () {
      store.set("APP_TOOLBAR_TITLE","ถอน E-Bonus");
      //console.log('Component created.');
  }    
}   
