//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify';

export default {
  name: 'MemberViewList',
  data () {
    return {
      init: false,
      navigationActive: false,
      table_disabled: false,
      loading: false,
      total_record: 0,
      total_page: 1,
      total_page_suffix: "",
      findBy:  {label: 'รหัสสมาชิก', value: 'mcode' },
      options: [
        {label: 'รหัสสมาชิก', value: 'mcode' },
        {label: 'USERNAME', value: 'name_f' },
        {label: 'ชื่อสมาชิก', value: 'name_t' },
        {label: 'วันที่สมัคร', value: 'mdate' },
        {label: 'เลขบัตรประชาชน', value: 'id_card' }
      ],
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      findWord: '',
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum' },
        { name: 'mcode', align: 'center', label: 'รหัสสมาชิก', field: 'mcode'},
        { name: 'name_t', align: 'left', label: 'ชื่อสมาชิก', field: 'name_t'},
        { name: 'mdate',  align: 'center', label: 'วันที่สมัคร', field: 'mdate' }
      ]
    }
  },
  methods: {
      activateNavigation () {
        this.navigationActive = true
      },
      deactivateNavigation () {
        this.navigationActive = false
      },
      onKey (evt) {
        if (
          this.navigationActive !== true ||
          [ 33, 34, 35, 36, 38, 40 ].indexOf(evt.keyCode) === -1 ||
          this.$refs.myTable === void 0
        ) {
          return
        }

        evt.preventDefault()

        const { computedRowsNumber, computedRows } = this.$refs.myTable

        if (computedRows.length === 0) {
          return
        }

        const currentIndex = this.selected.length > 0 ? computedRows.indexOf(this.selected[0]) : -1
        const currentPage = this.pagination.page
        const rowsPerPage = this.pagination.rowsPerPage === 0 ? computedRowsNumber : this.pagination.rowsPerPage
        const lastIndex = computedRows.length - 1
        const lastPage = Math.ceil(computedRowsNumber / rowsPerPage)

        let index = currentIndex
        let page = currentPage

        switch (evt.keyCode) {
          case 36: // Home
            page = 1
            index = 0
            break
          case 35: // End
            page = lastPage
            index = rowsPerPage - 1
            break
          case 33: // PageUp
            page = currentPage <= 1 ? lastPage : currentPage - 1
            if (index < 0) {
              index = 0
            }
            break
          case 34: // PageDown
            page = currentPage >= lastPage ? 1 : currentPage + 1
            if (index < 0) {
              index = rowsPerPage - 1
            }
            break
          case 38: // ArrowUp
            if (currentIndex <= 0) {
              page = currentPage <= 1 ? lastPage : currentPage - 1
              index = rowsPerPage - 1
            }
            else {
              index = currentIndex - 1
            }
            break
          case 40: // ArrowDown
            if (currentIndex >= lastIndex) {
              page = currentPage >= lastPage ? 1 : currentPage + 1
              index = 0
            }
            else {
              index = currentIndex + 1
            }
            break
        }

        if (page !== this.pagination.page) {
          this.pagination = {
            ...this.pagination,
            page
          }

          this.$nextTick(() => {
            const { computedRows } = this.$refs.myTable
            this.selected = [ computedRows[Math.min(index, computedRows.length - 1)] ]
          })
        }
        else {
          this.selected = [ computedRows[index] ]
        }
        this.$emit("onSeleted", this.selected[0].mcode);
      },
      previous_page: function() {
        if (parseInt(this.pageID) > 1) {
           this.pageID = parseInt(this.pageID) - 1;
        }
      },
      next_page: function() {
        if (parseInt(this.pageID) < parseInt(this.total_page)) {
           this.pageID = parseInt(this.pageID) + 1;
        }
      },
      requestRowClick: function(selected_mcode, selected_row) {
        if (!this.table_disabled) {
          //alert("row click :" + selected_mcode);
          if (this.selected.mcode !== selected_mcode) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
            this.$emit("onSeleted", selected_row.mcode);
          }
        }  
        //this.$emit("onSeleted", selected_row.mcode);
      },
      fetchDataList: function () {
          let self = this;
          self.loading = true;
          const params = new URLSearchParams();
          params.append('pageSize', this.rowsPerFetch);
          params.append('pageID', this.pageID);
          params.append('findBy', this.findBy.value);
          params.append('findWord', this.findWord);
          params.append('mcode',  this.session_mcode);
          self.axios({
            method: 'post',
            headers: {
              'Authorization': self.jwt_token
            },
            timeout: 0,
            url: self.url_api + 'MemberClient/MemberRegis.List.JSON.aspx',
            data: params
          })
          .then(function (response) {
              //console.log("response data result :" + response.data);
              let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
              let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
              let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
              if (record_count > 0) {
                self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
                self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
                self.total_record = record_count;
                self.total_page = page_count;
                self.loading = false;
                self.init = true;
                self.$emit("init",self.selected[0].mcode);
              } else {
                self.datalist = [];
                self.selected = [];
                self.total_record = 0;
                self.total_page = 1;
                self.loading = false;
                self.init = true;
              }
              /*
              console.log(response.status);
              console.log(response.statusText);
              console.log(response.headers);
              console.log(response.config);
              */
          })
          .catch(function (error) {
              console.log("Error :" + error);
              self.loading = false;
          });
      }
  },
  mounted () {
      //console.log('Component mounted.');
      this.fetchDataList();
  },
  watch: {
    pageID: function () {
      if (this.init) {
        this.fetchDataList();
      }
    }
  },
  created: function () {
      //console.log('Component created.');
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      tableClass () {
        return this.navigationActive === true ? 'shadow-8 no-outline' : void 0
      }
  }
}
