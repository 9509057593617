//
//
//

import fromCDN from "from-cdn";

export default {
  created:function(){
    this.ready = fromCDN([
      //"//cdn.dhtmlx.com/diagram/2.2/diagram.js"
      "//localhost/GDClient/dhtmlx/diagram.js"
    ]);
  },
  mounted:function(){
    this.ready.then(() => {
      /* globals dhx */
      var diagram = new dhx.Diagram(this.$refs.container);
      diagram.data.parse([
        { id: 1, x: 100, y: 40, text: "Start", type: "start", height: 50 },
        { id: 2, x: 100, y: 170, text: "Operation 1", type: "output" },
        { id: 3, x: 100, y: 300, text: "Operation 2", type: "input" },
        { id: 4, x: 20, y: 430, text: "End 1", type: "end", height: 50 },
        { id: 5, x: 280, y: 250, text: "Extra Step", type: "process" },
        { id: 6, x: 350, y: 60, text: "End 2", type: "end", height: 50 },
    
        { id: 7, x: 620, y: 40, text: "Start 2", type: "start", height: 50 },
        { id: 8, x: 500, y: 170, type: "input", text: "Operation 3" },
        { id: 9, x: 620, y: 300, text: "End 3", type: "end", height: 50 },
        { id: 10, x: 750, y: 180, text: "Reload", type: "process" },
    
        { from: 1, to: 2 },
        { from: 2, to: 3 },
        { from: 3, to: 4, forwardArrow: "filled" },
        { from: 3, to: 5, forwardArrow: "filled", backArrow: "filled" },
        { from: 5, to: 6, backArrow: "filled" },
    
        { from: 7, to: 8, forwardArrow: "filled" },
        { from: 8, to: 9, forwardArrow: "filled" },
        { from: 9, to: 10, forwardArrow: "filled" },
        { from: 10, to: 7, forwardArrow: "filled" }
      ]);
    });
  }
}
