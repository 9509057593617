//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify';

export default {
  name: 'DistrictDialog',
  props: ["show_status"],
  data () {
    return {
      loading: false,
      fixed: false,
      district_readonly: true,
      subdistrict_readonly: true,
      input_editable_color: "grey-2",
      district_input_editable_color: "grey-2",
      subdistrict_input_editable_color: "grey-2",
      provinceList: null,
      provinceOptions: this.provinceList,
      province_selected : null,
      districtList: null,
      districtOptions: this.districtList,
      district_selected : null,
      subdistrictList: null,
      subdistrictOptions: this.subdistrictList,
      subdistrict_selected : null,
      zipcode : ""
    }
  },
  methods: {
        reset_form: function() {
            this.provinceList = [];
            this.districtList = [];
            this.subdistrictList = [];
            this.province_selected = {label: "", value: ""};
            this.district_selected = {label: "", value: ""};
            this.subdistrict_selected = {label: "", value: ""};
            this.zipcode = "";
            this.district_readonly = true;
            this.subdistrict_readonly = true;
            this.fetchProvince();
        },
        confirm_data: function() {
          this.fixed = false;
          this.$emit("onSelected", this.province_selected.label, this.district_selected.label, this.subdistrict_selected.label, this.zipcode);
          //this.$refs.qDialog.hide();
        },
        fetchProvince: function () {
          let self = this;
          self.axios({
          method: "post",
            url: self.url_api + "MemberClient/ProvinceData.ListAll.JSON.aspx"
          })
          .then(function (response) {
            //console.log("province result :" + response.data[0].label);
            self.provinceList = response.data;
          })
          .catch(function (error) {
            console.log("Error :" + error);
          });
        },
        fetchDistrict: function () {
          let self = this;
          self.loading = true;
          const params = new URLSearchParams();
          params.append('province_id', this.province_selected.value);
          self.axios({
          method: "post",
            url: self.url_api + "MemberClient/District.ListAll.JSON.aspx",
            data: params
          })
          .then(function (response) {
            //console.log("district result :" + response.data[0].label);
            self.districtList = response.data;
          })
          .catch(function (error) {
            console.log("Error :" + error);
          });
        },
        fetchSubDistrict: function () {
          let self = this;
          self.loading = true;
          const params = new URLSearchParams();
          params.append('province_id', this.province_selected.value);
          params.append('district_id', this.district_selected.value);
          self.axios({
          method: "post",
            url: self.url_api + "MemberClient/SubDistrict.ListAll.JSON.aspx",
            data: params
          })
          .then(function (response) {
            //console.log("district result :" + response.data[0].label);
            self.subdistrictList = response.data;
          })
          .catch(function (error) {
            console.log("Error :" + error);
          });
        },
        fetchZipCode: function () {
          let self = this;
          self.loading = true;
          const params = new URLSearchParams();
          params.append('province_id', this.province_selected.value);
          params.append('district_id', this.district_selected.value);
          params.append('subdistrict_id', this.subdistrict_selected.value);
          self.axios({
          method: "post",
            url: self.url_api + "MemberClient/ZipCodeInfo.aspx",
            data: params
          })
          .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            self.zipcode = responseDoc.getElementsByTagName('zipcode')[0].textContent;
          })
          .catch(function (error) {
            self.zipcode = "";
            console.log("Error :" + error);
          });
        },
        setProvinceSelected(selected_value) {
          //console.log("province selected_value:" + selected_value);
          var found = this.provinceList.find(function(element) { 
            return element.label == selected_value; 
          });
          this.province_selected = found;
        },  
        filterFnAutoselect (val, update) {
          // call abort() at any time if you can't retrieve data somehow
          setTimeout(() => {
            update(
              () => {
                if (val === '') {
                  this.provinceOptions = this.provinceList
                }
                else {
                  const needle = val.toLowerCase()
                  this.provinceOptions = this.provinceList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                  //console.log("filter result:" + this.provinceOptions[0].label);
                  /*
                  const result = members.filter((jsonOptions) => {
                    return jsonOptions.label > 25
                  })
                  this.options = result*/

                }
              },

              // next function is available in Quasar v1.7.4+;
              // "ref" is the Vue reference to the QSelect
              
              ref => {
                if (val !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
                  ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                  ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
                }
              }
            )
          }, 300)
        },
        filterDistrictFnAutoselect (val, update) {
          // call abort() at any time if you can't retrieve data somehow
          setTimeout(() => {
            update(
              () => {
                if (val === '') {
                  this.districtOptions = this.districtList
                }
                else {
                  const needle = val.toLowerCase()
                  this.districtOptions = this.districtList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                  //console.log("filter result:" + this.districtOptions[0].label);
                  /*
                  const result = members.filter((jsonOptions) => {
                    return jsonOptions.label > 25
                  })
                  this.options = result*/

                }
              },

              // next function is available in Quasar v1.7.4+;
              // "ref" is the Vue reference to the QSelect
              
              ref => {
                if (val !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
                  ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                  ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
                }
              }
            )
          }, 300)
        },
        filterSubDistrictFnAutoselect (val, update) {
          // call abort() at any time if you can't retrieve data somehow
          setTimeout(() => {
            update(
              () => {
                if (val === '') {
                  this.subdistrictOptions = this.subdistrictList
                }
                else {
                  const needle = val.toLowerCase()
                  this.subdistrictOptions = this.subdistrictList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                  //console.log("filter result:" + this.subdistrictOptions[0].label);
                  /*
                  const result = members.filter((jsonOptions) => {
                    return jsonOptions.label > 25
                  })
                  this.options = result*/

                }
              },

              // next function is available in Quasar v1.7.4+;
              // "ref" is the Vue reference to the QSelect
              
              ref => {
                if (val !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
                  ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                  ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
                }
              }
            )
          }, 300)
        },
        abortFilterFn () {
            // console.log('delayed filter aborted')
        },
        onHide() {
          //console.log("onHide");
          this.$emit("onHide", "");
          //this.$parent.scode_dialog_show = false;
        },
        /*
        onShow() {
          this.reset_form();
        }
        
        ,
        // when props.ok() gets called
        onOk (data) { },

        // when props.cancel() gets called
        onCancel () { },

        // when we show it to the user
        onShow () { },

        // when it gets hidden
        onHide () { },

        // custom handler
        async choose (okFn, hero) {
          if (this.name.length === 0) {
            this.$q.dialog({
              title: 'Please specify your name!',
              message: `Can't buy tickets without knowing your name.`
            })
          }
          else {
            await okFn()
            this.$q.notify(`Ok ${this.name}, going with ${hero}`)
          }
        }
        */
  },
  mounted () {
      //console.log('MemberPasswordDialog mounted.');
      this.fetchProvince();
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
      if (this.fixed) {
        this.reset_form();
      }
    },
    province_selected: function () {
      if (this.province_selected.value.toString().length > 0) {
        this.district_readonly = false;
        this.district_input_editable_color = this.editableColor;
        this.fetchDistrict();
      } 
    },
    district_selected: function () {
      if (this.province_selected.value.toString().length > 0 && this.district_selected.value.toString().length > 0) {
        this.subdistrict_readonly = false;
        this.subdistrict_input_editable_color = this.editableColor;
        this.fetchSubDistrict();
      }
    },
    subdistrict_selected: function () {
      if (this.province_selected.value.toString().length > 0 && this.district_selected.value.toString().length > 0 && this.subdistrict_selected.value.toString().length > 0 ) {
        this.fetchZipCode();
      }
    }
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      editableColor: get("EDITABLE_COLOR"),
  },
  created () {
  }
 
}
