//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*eslint no-mixed-spaces-and-tabs: ["error", "smart-tabs"]*/
import { get } from "vuex-pathify";
var JQuery = require("jquery");
var mobiscroll = require('../assets/mobiscroll/js/mobiscroll.jquery.min.js');

export default {
  name: 'MbscDatePicker',
  props: ['theme','dateFormat','value','title','editable',],
  data () {
    return {
        input_bgColor: "grey-2",
    }
  },
  methods: {
    show_date_picker: function() {
        var me = this;
        //console.log("show_date_picker editable :" + me.editable);
        if (me.editable) {
            //console.log("theme:" + me.theme);
            //console.log("start init" + me.theme);
            mobiscroll.settings = {
                lang: 'th',                           // Specify language like: lang: 'pl' or omit setting to use default
                theme: me.theme,                    // Specify theme like: theme: 'ios', 'material' or omit setting to use default
                themeVariant: 'light',            // More info about themeVariant: https://docs.mobiscroll.com/4-10-1/datetime#opt-themeVariant
                display: 'center'                     // Specify display mode like: display: 'bottom' or omit setting to use default
            }; 
            /*
            console.log("lenght : " + this.value.length.toString());
            console.log("value : " + this.value);
            console.log("dd : " + parseInt(this.value.substr(0,2)).toString() );
            console.log("mm : " + (parseInt(this.value.substr(3,2)) - 1 ).toString() );
            console.log("yyyy : " + (parseInt(this.value.substr(6,4)) - 543).toString() );
            */
                    var instance = JQuery(me.$refs.thead_001).mobiscroll().date ({
                        headerText: me.title + " : " + '{value}',            // More info about headerText: https://docs.mobiscroll.com/4-10-1/datetime#opt-headerText
                        showOnTap: false,                 // More info about showOnTap: https://docs.mobiscroll.com/4-10-1/datetime#opt-showOnTap
                        showOnFocus: false,               // More info about showOnFocus: https://docs.mobiscroll.com/4-10-1/datetime#opt-showOnFocus
                        dateFormat: me.dateFormat,
                        onInit: function (event, inst) {  // More info about onInit: https://docs.mobiscroll.com/4-10-1/datetime#event-onInit
                            //console.log("value : " + me.value);
                            //inst.setVal(new Date(), true);
                            //console.log("value : " + me.value);
                            if (me.value.length > 0) {
                            inst.setVal(new Date(parseInt(me.value.substr(6,4))-543, parseInt(me.value.substr(3,2))-1, parseInt(me.value.substr(0,2))), true);
                            } else {
                            inst.setVal(new Date(), true);
                            }
                        },
                        getYear: function (date) {
                        var year = date.getFullYear() + 543;
                        return year;
                        },
                        getDate: function (y, m, d, h, i, s, u) {
                            return new Date(y - 543, m, d, h || 0, i || 0, s || 0, u || 0);
                        },
                        getMaxDayOfMonth: function (y, m) {
                            // Should return the number of days in a month
                            var daysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
                            year = y - 543,
                            leapYear = ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);

                            return daysPerMonth[m] + (m === 1 && leapYear ? 1 : 0);
                        }
                    }).mobiscroll('getInst');
                //console.log("finish init");
            instance.show();
        }    
    }
  },
  mounted: function() {
    var me = this;
    JQuery(me.$refs.thead_001).on('change', function (e) {
        //console.log("set emit value to input");
        me.$emit('updateDate', e.target.value, me.title);
    });
    /*
    JQuery(me.$refs.thead_001).click(function () {
        console.log("click mdate");
        //instance.show();
        return false;
    });
    */
    /*
    console.log("instance :" + instance.value );
    console.log("me.value :" + jQuery(me.$refs.thead_001).val() );
    */
  },
  computed: {
      editableColor: get("EDITABLE_COLOR")
  }    
}
