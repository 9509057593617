//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fromCDN from "from-cdn";
import { get } from 'vuex-pathify';
import store from '../store/index';
import UplineTreeFindDialog from '@/components/UplineTreeFindDialog.vue';

export default {
  components: {
      UplineTreeFindDialog
  },
  data() {
    return {
      fab_expand: false,
      ui_size: "",
      upline_tree_find_dialog_show: false,
      mcode: "",
      gotoUpline_disable: true,
      goBack_disable: true,
      navi_array: [],
      mcode_up: "",
      mcode_left: "",
      mcode_right: "",
      mcode_curr: "",
      splitterModel: 70, // start at 25%,
      splitterModelMobile: 50, // start at 50%,
      member: "",
      readonly: true,
      input_editable_color: "grey-1",
      uplineDiagram: null,
      searchText: "",
      messages:"AAA",
      memberLists: []
    }
  },
  mounted:function() {
    this.mcode = this.session_mcode;
    this.mcode_curr = this.mcode;
    this.fetchUpline();
  },
  methods: {
      renderDiagram () {
          let me = this;
          //console.log(me.memberLists);
          this.ready.then(() => {
              /* globals dhx */
              var diagram = new dhx.Diagram(this.$refs.container, {
                  type: "org",
                  defaultShapeType: "svg-card",
                  scale: 0.8,//1.0,
                  margin: { itemX: 35, itemY: 30, x: 25, y: 20 },
                  select: true,
                  /*
                  toolbar:[{
                              id:"root",
                              content:"<i class='zmdi zmdi-device-hub'></i>"
                          }] */
              });
              diagram.data.parse(me.memberLists);

              diagram.events.on("ShapeClick", function (id) {
              let selected_item = diagram.data.getItem(id);
              //console.log("selected_item :" + selected_item.mcode + " current mcode : " +me.member.mcode);
              /*
              console.log("emit click :" + selected_item.color);
              alert(selected_text);
              me.messages = "mcode : " + id + " name : "+ selected_item.mcode;
              */
              //alert(me.isMobileDevice());
              //alert("up_code value : " + selected_item.up_code + " side:" + selected_item.side);
              if ( me.member.mcode == selected_item.mcode ) {
                  if (selected_item.mcode.substring(0,1) != "#") {
                      me.navi_array.push(me.mcode_curr);
                      me.mcode_curr = selected_item.mcode;
                      me.fetchUpline();
                  } else {
                      //alert("register new member");
                      //alert("up_code value : " + selected_item.up_code + " side:" + selected_item.side);
                      store.set("INIT_UP_CODE", selected_item.up_code);
                      store.set("INIT_SIDE", selected_item.side);
                      me.saveState();
                      me.$router.push({ name:"Registration"});
                  }
              } else {
                  me.member = selected_item;
              }
              });
              /*
              diagram.events.on("ShapeHover", function (id) {
                  //alert(me.isMobileDevice());
                  let selected_item = diagram.data.getItem(id);
                  //alert("mcode : " + id + " name : "+ selected_item.mcode);
                  console.log("Hover mcode : " + id + " name : "+ selected_item.mcode);
                  if (!me.isMobileDevice()) {
                      me.member = selected_item;
                      diagram.selection.add(id);
                  }
              });
              diagram.events.on("ShapeIconClick", function(icon) {
                  let id = diagram.selection.getId();
                  alert(icon + " was clicked for id = " + id);
                  me.navi_array.push(me.mcode_curr);
                  me.mcode_curr = id;
                  me.fetchUpline();
              });
              
              diagram.events.on("ShapeDblClick", function (id) {
                  let selected_item = diagram.data.getItem(id);
                  if (me.isMobileDevice()) {
                      if (selected_item.mcode.substring(0,1) != "#") {
                          me.navi_array.push(me.mcode_curr);
                          me.mcode_curr = selected_item.mcode;
                          me.fetchUpline();
                      } else {
                          alert("register new member");
                      }
                  }
              });
              */
              /*
              function zoomIn() {
                  diagram.config.scale = diagram.config.scale + 0.1;
                  diagram.paint();
              }

              function zoomOut() {
                  diagram.config.scale = diagram.config.scale - 0.1;
                  diagram.paint();
              }
              */
              me.uplineDiagram = diagram;
          });
      },
      /*
      isMobileDevice: function() {
          if (sessionStorage.desktop) // desktop storage 
              return false;
          else if (localStorage.mobile) // mobile storage
              return true;
          // alternative
          var mobile = ['iphone','ipad','android','blackberry','nokia','opera mini','windows mobile','windows phone','iemobile']; 
          for (var i in mobile) if (navigator.userAgent.toLowerCase().indexOf(mobile[i].toLowerCase()) > 0) return true;
          // nothing found.. assume desktop
          return false;
      },*/
      onClick () {
      // console.log('Clicked on a fab action')
      },
      setUISize() {
          //if (this.$q.screen.width > 1023) { 
          if (this.$q.screen.width > 800) {
          console.log('screen large');
          this.ui_size = "D";
          }
          else {
          console.log('screen small');
          this.ui_size = "M";
          }
      },
      gotoSelectedMember (e) {
          try {
              //console.log("goto selected mcode :" + e.mcode);
              this.navi_array.push(this.mcode_curr);
              this.mcode_curr =  e.mcode;
              this.fetchUpline();
          } catch {
              console.log("not select mcode");
          }
      },
      upline_tree_find_dialog_hide () {
          this.upline_tree_find_dialog_show = false;
          //console.log("hide set upline_tree_find_dialog_show = " + this.upline_tree_find_dialog_show);
      },
      findMember: function() {
          if (this.searchText.length >= 2) {
              //alert("Search Text : " + this.searchText);
              this.upline_tree_find_dialog_show = true;
          }
      },
      gotoRoot: function () {
          //console.log("this.mcode : " + this.mcode);
          this.navi_array = [];
          this.mcode_curr = this.mcode;
          this.fetchUpline();
      },
      goBack: function () {
          if (this.navi_array.length > 0) {
              this.mcode_curr = this.navi_array.pop();
              this.fetchUpline();
          }
      },
      gotoUpline: function () {
          //console.log(" mcode up : " + this.mcode_up);
          this.navi_array.push(this.mcode_curr);
          this.mcode_curr = this.mcode_up;
          this.fetchUpline();
      },
      gotoLeaf: function (side) {
          //console.log(" mcode left : " + this.mcode_left);
          let self = this;
          const params = new URLSearchParams();
          params.append("mcode", self.mcode_curr);
          params.append("side", side)
          self.$q.loading.show();
          self.axios({
          method: "post",
          headers: {
              'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/UplineTreeChart.Leaf.Node.JSON.aspx",
          data: params
          })
          .then(function (response) {
              let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
              let mcode_leaf = responseDoc.getElementsByTagName('mcode_leaf')[0].textContent;
              if (side == "L") {
                  self.mcode_left = mcode_leaf;
              } else {
                  self.mcode_right = mcode_leaf;
              }
              self.navi_array.push(self.mcode_curr);
              self.mcode_curr = mcode_leaf;
              self.fetchUpline();
              //self.$q.loading.hide();
          })
          .catch(function (error) {
              console.log("Error :" + error);
              self.$q.loading.hide();
          });
      },
      diagramZoomIn: function () {
          //console.log("zooo in");
          
          this.uplineDiagram.config.scale = this.uplineDiagram.config.scale + 0.1;
          this.uplineDiagram.paint();
          
      },
      diagramZoomOut: function () {
          //console.log("zooo out");
          
          this.uplineDiagram.config.scale = this.uplineDiagram.config.scale - 0.1;
          this.uplineDiagram.paint();
          
      },
      diagramFit: function () {
          //console.log("zooo out");
          
          this.uplineDiagram.config.scale = 0.50;
          this.uplineDiagram.paint();
          
      },
      fetchUpline: function () {
          //console.log("fetch upline tree");
          //params.append("mcode", this.mcode);
          //this.mcode_curr = this.session_mcode;
          let self = this;
          //console.log("session-mcode : " + self.session_mcode);
          try {
              if (self.$route.params.mcode != undefined && self.$route.params.mcode.length == self.mcode_length) {
                  //console.log("assign mcode_curr from route");
                  self.mcode_curr = self.$route.params.mcode;
                  self.$route.params.mcode = "";
              } else {
                  //self.mcode_curr = self.session_mcode;
              }
          } catch (err) {
              console.log("fetchUpline Error: " + err.message);
              self.mcode_curr = self.session_mcode;
          }
          //console.log("mcode_curr : " + self.mcode_curr);
          const params = new URLSearchParams();
          params.append("mcode", self.mcode_curr);
          
          self.$q.loading.show();
          self.axios({
          method: "post",
          headers: {
              'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/UplineTreeChart.JSON.aspx",
          data: params
          })
          .then(function (response) {
              let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
              
              self.mcode_up = responseDoc.getElementsByTagName('mcode_up')[0].textContent;
              //self.mcode_left = responseDoc.getElementsByTagName('mcode_left')[0].textContent;
              //self.mcode_right = responseDoc.getElementsByTagName('mcode_right')[0].textContent;
              self.mcode_curr = responseDoc.getElementsByTagName('mcode_curr')[0].textContent;
              /*
              console.log(responseDoc.getElementsByTagName('json')[0].textContent);
              console.log("record count " + record_count.toString());
              console.log("page count " + responseDoc.getElementsByTagName('pagecount')[0].textContent);
              */
              //console.log(responseDoc.getElementsByTagName('json')[0].textContent);
              //self.memberLists = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.memberLists = responseDoc.getElementsByTagName('json')[0].textContent;
              self.uplineDiagram.data.parse(self.memberLists);
              let selectedID = self.mcode_curr;
              //console.log("self mcode:"+ selectedID);
              
              self.uplineDiagram.selection.add(selectedID);
              self.member = self.uplineDiagram.data.getItem(selectedID);
              //self.member = self.memberLists[1];
              //self.loading = false;
              //console.log(self.memberLists);
              setTimeout(() => {
                  self.$q.loading.hide();
              }, 800);
          })
          .catch(function (error) {
              console.log("Error :" + error);
              self.memberLists = [];
              self.$q.loading.hide();
          });
      },
      saveState () {
          const serializedState = JSON.stringify(this.$store.state)
          sessionStorage.setItem('appGDMemberState', serializedState)
      },
  },
  computed: {
    url_api: get('URL_API_PATH'),
    jwt_token: get('JWT_TOKEN'),
    mcode_length: get('APP_MCODE_LENGTH'),
    appRowsPerFetch: get('APP_ROW_PER_FETCH'),
    session_mcode: get('SESSION_MCODE_LOGIN')
  },
  created:function(){
    this.setUISize();
    let cdn_URL = this.url_api.replace("aspx/","dhtmlx/diagram.js");
    this.ready = fromCDN([
      //"//cdn.dhtmlx.com/diagram/2.2/diagram.js"
      //"http://localhost/GDClient/dhtmlx/diagram.js"
      cdn_URL
    ]);
    store.set("APP_TOOLBAR_TITLE","ผังทีมงานไบนารี่");
    this.renderDiagram();
    /*
    this.mcode = this.session_mcode;
    this.mcode_curr = this.mcode;
    this.fetchUpline();
    */
  },
  watch: {
      "$q.screen.width"() {
          this.setUISize()
      },
      navi_array: function() {
          if (this.navi_array.length == 0) {
              this.goBack_disable = true;
          } else {
              this.goBack_disable = false;
          }
      },
      mcode_curr: function() {
          if (this.mcode_curr == this.mcode) {
              this.gotoUpline_disable = true;
          } else {
              this.gotoUpline_disable = false;
          }
      }
  }
}
