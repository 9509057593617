//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'

export default {
  name: "EstoreOrdersDetailDialog",
  props: ["show_status","number"],

  data () {
    return {
      fixed: false,
      dialog_style : "background-color:#f5fafe; width: 740px; max-width: 80vw;",
      estore_orders_detail_dialog_show: false,
      loading: false,
      pagination: {
        rowsPerPage: 0
      },
      query:"1",
      datalist: [],
      selected: [],
      columns: [
        { name: 'id', align: 'center', label: 'รายการ', field: 'id'},
        { name: 'prodcode', align: 'center', label: 'รหัสสินค้า', field: 'prodcode'},
        { name: 'prodname', align: 'left', label: 'ชื่อสินค้า', field: 'prodname'},
        { name: 'price', align: 'right', label: 'ราคา', field: 'price'},
        { name: 'pv', align: 'right', label: 'PV', field: 'pv' },
        { name: 'quantity', align: 'right', label: 'จำนวน', field: 'quantity' },
        { name: 'total_ba', align: 'right', label: 'รวมเงิน', field: 'total_ba' },
        { name: 'total_pv', align: 'right', label: 'รวม PV', field: 'total_pv'}
      ]
    }
  },
  methods: {
      onHide() {
        //console.log("onHide");
        this.$emit("onHide", "");
        this.datalist = [];
        this.selected = [];
        //this.$parent.scode_dialog_show = false;
      },
      onShow() {
        this.$q.loading.show();
        this.getData(); 
      },
      requestRowClick: function(selected_id, selected_row) {
          //console.log("row click :" + selected_mcode);
          if (this.selected.id !== selected_id) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
            //this.$emit("onSeleted", selected_row);
          }
      },
      getData: function () {
        var self = this;
        self.loading = true;
        self.rowsPerFetch = 200;//self.appRowsPerFetch;
        const params = new URLSearchParams();
        params.append('number', self.number);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 0,
          url: self.url_api + 'MemberClient/eStore.GetBillHistoryDetail.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            //let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
            self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
            self.loading = false;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  },
  mounted () {
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
    }
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN')
  },
  created () {
      //console.log('Component created.');
  }
}
