//
//
//
//
//
//
//
//
//

export default {
  name: 'HelloWorld'
}
