import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './quasar'

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
Vue.filter('numFormat', numFormat(numeral));

Vue.config.productionTip = false

import { format } from 'quasar'
const { pad } = format

var mixin;
Vue.mixin({
  
  methods: {

    paddingCode: function(input_txt) {
      let output_txt;
      if (input_txt.substring(0,2) == "TH") {
        output_txt = "TH" + pad(input_txt.substring(2,9),7,'0');
      } else {
        if ( !isNaN(parseInt(input_txt)) ) {
          output_txt = pad(input_txt,9,'0');
        } else {
          output_txt = input_txt;
        }
      }
      return(output_txt);
    },
    escapeRegExp(string) {
      return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    },
    replaceAll(str, find, replace) {
      return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    },
    formatnumber(num, decimal) {
      return num.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    },
    windowOpenWithPost(actionUrl, windowName, windowFeatures, data) 
    {
          if (windowFeatures == "") {
            windowFeatures = "directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,height=600,width=800,scrollbars=1";
          }
          let mapForm = document.createElement("form");
          let milliseconds = new Date().getTime();
          windowName = windowName+milliseconds;
          mapForm.target = windowName;
          mapForm.method = "POST";
          mapForm.action = actionUrl;
          for (let key in data) {
                  let mapInput = document.createElement("input");
                  mapInput.type = "hidden";
                  mapInput.name = key;
                  mapInput.value = data[key];
                  mapForm.appendChild(mapInput);
              }
          document.body.appendChild(mapForm);

          let map = window.open("", windowName, windowFeatures);
          if (map) {
              mapForm.submit();
          } else {
              alert('You must allow popups.');
          }
          this.setWindowTitle(map, windowName);  
    },
    setWindowTitle(mapWin, title)
    {
          if (mapWin.document) // If loaded
          {
              mapWin.document.title = title;
          }
          else // If not loaded yet
          {
              setTimeout(this.setWindowTitle, 10); // Recheck again every 10 ms
          }
    }

  }

})

new Vue({
  mixin,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
