//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { get } from "vuex-pathify";
import { date } from "quasar";
import MemberListDialog from '@/components/MemberListDialog.vue'
import MbscDatePicker from '@/components/MbscDatePicker.vue'
import RegistrationBillDialog from '@/components/RegistrationBillDialog.vue'
import DistrictDialog from '@/components/DistrictDialog.vue'

export default {
  components: {
    MemberListDialog,
    MbscDatePicker,
    RegistrationBillDialog,
    DistrictDialog
  },
  name: "RegistrationForm",
  props: ["mcode", "show", "init_up_code", "init_side"],
  data () {
    return {
        valid_epoint_password: false,
        valid_password: false,
        new_registration: false,
        init_up_code_flag: false,
        email_valid: false,
        submit_btn_disable: false,
        current_mcode: "",
        add_btn_panel: "",
        insert_btn_panel: "hidden",
        isPwd: true,
        bill_dialog_show: false,
        idaddress_district_dialog_show: false,
        address_district_dialog_show: false,
        scode_dialog_show: false,
        spcode_dialog_show: false,
        upcode_dialog_show: false,
        member_password_dialog_show: false,
        new_member: {number:"", prodcode:"", prodid:"", mcode:"",position:"",rank:"",srank:"",sex:"1",mstatus:"",name_f:"",name_t:"",name_e:"",mdate:"",birthday:"01-01-2530",id_card:"",id_tax:"",address1:"",address2:"",province:"",zip:"",idaddress1:"",idaddress2:"",idprovince:"",idzip:"",telephone:"",mobile:"",email:"",document:"",bookbank:"",bonusrec:"4",bankcode:"",branch:"",acc_type:"",acc_no:"",acc_name:"",fortune:"",up_code:"",up_name:"",sp_code:"",sp_name:"",side:"L",ppvtotal:"",pv_l:"",pv_r:"",total_l:"",total_r:"",gpvtotal:"",password:"",epoint_password:"",user:"",update:"",abonus:"",pba:"",ppv:"",pbv:"",spv:"",vip:"",sms:"",amaintain:"",mcard:"",uppos:"",expire:"",expdate:"",terminate:"",ppvplanb:"",maintain:"",myson:"",autoship:"",rcode:"",branch_name:"",pv_maintain:"",scode:"",sname:"",remark:"",step:"",new_bonusrec:"",ebonus:""},
        member: {number:"", prodcode:"", prodid:"", mcode:"",position:"",rank:"",srank:"",sex:"1",mstatus:"",name_f:"",name_t:"",name_e:"",mdate:"",birthday:"",id_card:"",id_tax:"",address1:"",address2:"",province:"",zip:"",idaddress1:"",idaddress2:"",idprovince:"",idzip:"",telephone:"",mobile:"",email:"",document:"",bookbank:"",bonusrec:"4",bankcode:"",branch:"",acc_type:"",acc_no:"",acc_name:"",fortune:"",up_code:"",up_name:"",sp_code:"",sp_name:"",side:"L",ppvtotal:"",pv_l:"",pv_r:"",total_l:"",total_r:"",gpvtotal:"",password:"",epoint_password:"",user:"",update:"",abonus:"",pba:"",ppv:"",pbv:"",spv:"",vip:"",sms:"",amaintain:"",mcard:"",uppos:"",expire:"",expdate:"",terminate:"",ppvplanb:"",maintain:"",myson:"",autoship:"",rcode:"",branch_name:"",pv_maintain:"",scode:"",sname:"",remark:"",step:"",new_bonusrec:"",ebonus:""},
        input_editable_color: "grey-2",
        side_toggle_color: "grey-8",
        width: 400,
        splitterAddressPanel: 50, // start at 50%
        readonly: true,
        password_visible: "hidden",
        epoint_password_visible: "hidden",
        genderList: [
            {label: "นาย", value: "1" },
            {label: "น.ส.", value: "2" },
            {label: "นาง", value: "3" },
            {label: "บริษัท", value: "4" }
        ],
        gender_selected: {label:"นาย", value:"1" },
        mstatusList: [
            {label: "โสด", value: "" },
            {label: "สมรส", value: "1" },
            {label: "อื่นๆ", value: "2" }
        ],
        mstatus_selected: {label: "อื่นๆ", value: "2" },
        all_bonusrecList: [
            {label: "มารับด้วยตนเอง", value: "1" },
            {label: "โอนผ่านบัญชีธนาคาร", value: "2" },
            {label: "ระงับโบนัส", value: "3" },
            {label: "รับเป็น eBonus", value: "4" }
        ],
        new_bonusrecList: [
            {label: "โอนผ่านบัญชีธนาคาร", value: "2" },
            {label: "รับเป็น eBonus", value: "4" }
        ],
        bonusrecList: this.all_bonusrecList,
        bonusrec_selected: {label: "โอนผ่านบัญชีธนาคาร", value: "2" },
        provinceOptions: this.provinceList,
        province_selected : {label: "กรุงเทพฯ", value: "2"},
        idprovince_selected : {label: "กรุงเทพฯ", value: "2"},
        bankList: null,
        bankOptions:this.bankList,
        bank_selected: {label: "", value: "" },
        acc_no: "",
        acc_name: "",
        valid_username: true,
        valid_id_card: true,
        valid_upline: true,
        valid_sponsor: true,
        valid_name_t: true,
    }
  },
  methods: {
    setIDAddress(province, district, subdistrict, zipcode) {
      if (province.length > 0 && district.length > 0 && subdistrict.length > 0) {
        let district_abbr = "อ.";
        let subdistrict_abbr = "ต.";
        if (province == "กรุงเทพฯ" || province == "กรุงเทพมหานคร") {
          district_abbr = "เขต";
          subdistrict_abbr = "แขวง";
        }
        this.member.idaddress2 =  subdistrict_abbr + subdistrict + " " + district_abbr + district;
        this.member.idzip = zipcode;
        this.setIDProvinceSelected(province);
      }
    },
    setContactAddress(province, district, subdistrict, zipcode) {
      if (province.length > 0 && district.length > 0 && subdistrict.length > 0) {
        let district_abbr = "อ.";
        let subdistrict_abbr = "ต.";
        if (province == "กรุงเทพฯ" || province == "กรุงเทพมหานคร") {
          district_abbr = "เขต";
          subdistrict_abbr = "แขวง";
        }
        this.member.address2 =  subdistrict_abbr + subdistrict + " " + district_abbr + district;
        this.member.zip = zipcode;
        this.setProvinceSelected(province);
      }  
    },
    district_dialog_hide() {
      if (this.idaddress_district_dialog_show) { this.idaddress_district_dialog_show = false; }
      if (this.address_district_dialog_show) { this.address_district_dialog_show = false; }
    },
    validate_email() {
      this.member.email = this.member.email.trim();
      let email = this.member.email;
      let regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (regexEmail.test(email)) {
        this.email_valid = true;
      } else {
        this.email_valid = false;
      }
    },
    confirm_post() {
      //alert(this.member.prodid);
      let valid_all = true;
      let acc_no = this.replaceAll(this.replaceAll(this.member.acc_no,"#",""),"-","");
      let acc_name = this.member.acc_name.trim();

      if ( !this.compute_valid_username ||
           !this.compute_valid_name_t ||
           !this.compute_valid_id_card ||
           !this.compute_valid_mobile_no ||
           !this.compute_valid_upline ||
           !this.compute_valid_sponsor ||
           !this.valid_password ||
           !this.valid_epoint_password ||
           (this.member.email.trim().length > 0 && !this.compute_valid_email)
         ) 
      {
          valid_all = false;
      } 

      if (this.bonusrec_selected.value == "2") {
         if (!this.compute_valid_bank || !this.compute_valid_acc_no || !this.compute_valid_acc_name) {
           valid_all = false;
         }  
      } else {
         if (this.bonusrec_selected.value == "4" && (acc_no.length > 0 || acc_name.length > 0 || this.bank_selected.value != "") ) {
            if (!this.compute_valid_bank || !this.compute_valid_acc_no || !this.compute_valid_acc_name) {
              valid_all = false;
            }  
         }
      }

      if (valid_all) {
          //this.submit_btn_disable = true;
          let bonusrec_method = "การรับโบนัส : <b>";
          bonusrec_method = bonusrec_method + this.bonusrec_selected.label + "<b><br>" + 
                          " ธนาคาร : " + this.bank_selected.label + "&nbsp;&nbsp;สาขา : " + this.member.branch + "</br>" + 
                          " เลขที่บัญชี : " + this.member.acc_no + "&nbsp;&nbsp;ชื่อบัญชี : " + this.member.acc_name;
          this.$q.dialog({
            title: "ยืนยันการสมัครสมาชิก",
            message: " Username : <b>" + this.member.name_f + "</b></br>" +
                    " เลขบัตรประชาชน : <b>" + this.member.id_card + "</b><br>" +
                    " ชื่อ-นามสกุล : <b>" + this.member.name_t + "</b>&nbsp;&nbsp;&nbsp;มือถือ : <b>" + this.member.mobile + "</b></br>" + 
                    " รหัสผู้แนะนำ : <b>" + this.member.sp_code + " - " + this.member.sp_name + "</b></br>" + 
                    " รหัสอัพไลน์ : <b>" + this.member.up_code + " - " + this.member.up_name + "</b>&nbsp;ด้าน :<b>" + this.member.side + "</b></br>" + 
                    bonusrec_method,
            persistent: true,
            html: true,
            class: "app-font",
            style: "background-color:#FFFFFF; color:#000000;",
            ok: {
              label: "ตกลง",
              color: 'positive'
            },
            cancel: {
              label: "ยกเลิก"
            },
          }).onOk(() => {
            this.post_member();
            // console.log('>>>> OK')
          }).onOk(() => {
            // console.log('>>>> second OK catcher')
          }).onCancel(() => {
            this.submit_btn_disable = false;
            // console.log('>>>> Cancel')
          }).onDismiss(() => {
            // console.log('I am triggered on both OK and Cancel')
          });

      } else {
          
          let error_msg = "";
          let error_count = 0;
          if (!this.valid_password || !this.valid_epoint_password) {
            error_msg = error_msg + "รหัสผ่านต้องมีความยาวอย่างน้อย[6]ตัวอักษรและประกอบด้วยตัวเลข[0-9]และตัวอักษร[a-z]";
            error_count = error_count + 1;
          }
          if (!this.compute_valid_username) {
            error_msg = error_msg + " Username";
            error_count = error_count + 1;
          }
          if (this.member.password.length == 0) {
            error_msg = error_msg + " รหัสผ่าน";
            error_count = error_count + 1;
          }
          if (this.member.epoint_password.length == 0) {
            error_msg = error_msg + " รหัสผ่าน E-Bonus";
            error_count = error_count + 1;
          }
          if (!this.compute_valid_name_t) {
            error_msg = error_msg + " ชื่อ-นามสกุล";
            error_count = error_count + 1;
          }
          if (!this.compute_valid_id_card) {
            error_msg = error_msg + " เลขบัตรประชาชน";
            error_count = error_count + 1;
          }
          if (!this.compute_valid_sponsor) {
            error_msg = error_msg + " รหัสผู้แนะนำ";
            error_count = error_count + 1;
          }
          if (!this.compute_valid_upline) {
            error_msg = error_msg + " รหัสอัพไลน์";
            error_count = error_count + 1;
          }
          if (!this.compute_valid_email && this.member.email.length > 0) {
            error_msg = error_msg + " อีเมล";
            error_count = error_count + 1;
          }
          if (!this.compute_valid_mobile_no) {
            error_msg = error_msg + " เบอร์มือถือ";
            error_count = error_count + 1;
          }
          if (!this.compute_valid_bank && this.bonusrec_selected.value == "2") {
            error_msg = error_msg + " ธนาคาร";
            error_count = error_count + 1;
          }
          if (!this.compute_valid_acc_no && this.bonusrec_selected.value == "2") {
            error_msg = error_msg + " เลขที่บัญชี";
            error_count = error_count + 1;
          }
          if (!this.compute_valid_acc_name && this.bonusrec_selected.value == "2") {
            error_msg = error_msg + " ชื่อบัญชี";
            error_count = error_count + 1;
          }
          if (!this.compute_valid_bank && this.bonusrec_selected.value == "4" && (this.member.acc_name.trim().length > 0 || this.replaceAll(this.acc_no,"-","").length > 0) ) {
            error_msg = error_msg + " ธนาคาร";
            error_count = error_count + 1;
          }
          if (!this.compute_valid_acc_no && this.bonusrec_selected.value == "4" && (this.member.acc_name.trim().length > 0 || this.replaceAll(this.acc_no,"-","").length > 0) ) {
            error_msg = error_msg + " เลขที่บัญชี";
            error_count = error_count + 1;
          }
          if (!this.compute_valid_acc_name && this.bonusrec_selected.value == "4" && (this.member.acc_name.trim().length > 0 || this.replaceAll(this.acc_no,"-","").length > 0) ) {
            error_msg = error_msg + " ชื่อบัญชี";
            error_count = error_count + 1;
          }
          //alert("error_msg : " + error_msg);
          let self = this;
          let err_txt;
          if (error_count > 1) {
            err_txt = self.replaceAll(error_msg.trim()," ",", ");
          } else {
            err_txt = error_msg;
          }
          self.triggerNegative("ตรวจสอบข้อมูล " + err_txt);
          //this.triggerNegative(error_msg);
      }
     
    },
    post_member() {
        let self = this;
        self.$q.loading.show();

        let bankcode = this.bank_selected.value;
        if ( bankcode == 'undefined' ) {
          bankcode = "";
        }
        const params = new URLSearchParams();
        params.append('name_f', self.member.name_f);
        params.append('name_t', self.member.name_t);
        params.append('position', '01');
        //console.log(" gender : " + self.gender_selected.value);
        params.append('sex', self.gender_selected.value);
        params.append('name_e', '');
        params.append('mdate', self.member.mdate);
        params.append('expdate', '');
        params.append('mstatus', self.mstatus_selected.value);
        //console.log(" mstatus : " + self.mstatus_selected.value);
        params.append('id_card', self.member.id_card);
        params.append('id_tax', '');	
        params.append('birthday', self.member.birthday);
        //console.log(" birthday : " + self.member.birthday);
        params.append('idaddress1', self.member.idaddress1);
        params.append('idaddress2', self.member.idaddress2);
        //console.log(" idprovince : " + self.idprovince_selected.label);
        params.append('idprovince', self.idprovince_selected.value);
        //console.log(self.idprovince_selected.value);
        params.append('idzip', self.member.idzip);
        params.append('address1', self.member.address1);
        params.append('address2', self.member.address2);
        params.append('province', self.province_selected.value);
        //console.log(self.province_selected.value);
        params.append('zip', self.member.zip);
        params.append('telephone', self.member.telephone);
        params.append('mobile', self.member.mobile);
        params.append('email', self.member.email);
        params.append('bonusrec', self.bonusrec_selected.value);
        //console.log(self.bonusrec_selected.value);
        params.append('bankcode', bankcode);
        //console.log(self.bank_selected.value);
        params.append('branch', self.member.branch);
        params.append('acc_no', self.acc_no);
        params.append('acc_name', self.member.acc_name);
        params.append('acc_type', self.member.acc_type);
        params.append('fortune', self.member.fortune);
        params.append('sp_code', self.member.sp_code);
        params.append('sp_name', self.member.sp_name);
        params.append('up_code', self.member.up_code);
        params.append('up_name', self.member.up_name);
        params.append('side', self.member.side);
        params.append('number', self.member.number);
        params.append('prodcode', self.member.prodcode);
        params.append('prodID', self.member.prodid);
        params.append('scode', self.session_scode);
        params.append('remark', '');
        params.append('password', self.member.password);
        params.append('password_ebonus', self.member.epoint_password);
        params.append('member_register_free', 'false');
        params.append('session_mcode',self.session_mcode);

        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/Member.Insert.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let result = responseDoc.getElementsByTagName('insert_status')[0].textContent;
          let result_mcode = responseDoc.getElementsByTagName('mcode')[0].textContent;
          let result_txt;
          if (result != "error") {
            switch(result_mcode) {
              case "upcode":
                result_txt = "รหัสอัพไลน์ไม่สามารถต่อได้  กรุณาเลือกใหม่อีกครั้ง...";
                self.triggerNegative(result_txt);
                break;
              case "quse":
                result_txt = "ชุดสมัครไม่เพียงพอในการสมัครสายงาน...";
                self.triggerNegative(result_txt);
                break;
              case "mcode":
                result_txt = "รหัสสมาชิกมีผู้อื่นใช้งานแล้ว...";
                self.triggerNegative(result_txt);
                break;
              case "false":
                result_txt = "ระบบเกิดข้อผิดพลาดไม่สามารถสมัครสมาชิกได้...";
                self.triggerNegative(result_txt);
                break;
              case "error":
                result_txt = "ข้อมูลที่บันทึกไม่ถูกต้อง...";
                self.triggerNegative(result_txt);
                break;    
              default:
                result_txt = "บันทึกข้อมูลเรียบร้อย รหัสสมาชิก : "+ result_mcode;
                self.triggerPositive(result_txt);
                self.view_form();
                self.$emit("inserted",result_mcode);
            }
            self.$q.loading.hide();
          } else {
            result_txt = "ระบบไม่สามารถบันทึกข้อมูลได้";
            self.triggerNegative(result_txt);
            self.$q.loading.hide();
          }
          self.submit_btn_disable = false;
        })
        .catch(function (error) {
          self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
          console.log("Error :" + error);
          self.$q.loading.hide();
          self.submit_btn_disable = false;
        });
    },
    triggerPositive (msg_text) {
      this.$q.notify({
        type: 'positive',
        progress: true,
        message: msg_text,
        classes:'app-font'
      });
    },
    triggerNegative (msg_text) {
      this.$q.notify({
        type: 'negative',
        message: msg_text,
        classes:'app-font'
      })
    },
    cancel_registration () {
      this.new_registration = true;
      this.view_form();
    },
    registration () {
      this.new_registration = true;
      this.popupBillDialog();
    },
    popupBillDialog() {
      this.$q.loading.show();
      let self = this;
      setTimeout(() => {
        this.$refs.bill_dialog.fetchDataList();
        self.$q.loading.hide();
        self.bill_dialog_show = true;
      }, 1000);
    },
    member_password_updated () {
      this.fetchData();
    },
    updateDate(e, title) {
      //console.log("update mdate to :" + e);
      switch (title) {
        case "วันเกิด":
          this.member.birthday = e;
          break;
      }
    },
    init_form () {
      this.member.name_f = "";
      this.member.name_t = "";
      this.member.id_card = "";
      this.member.sp_code = "";
      this.member.sp_name = "";

      if (this.init_up_code != "" && this.new_registration == false) {
        this.init_up_code_flag = true;
        this.member.up_code = this.init_up_code ;
        this.member.side = this.init_side;
        /*
        this.member.sp_code = this.session_mcode;
        this.fetchSponsor();
        */
        //console.log("init_up_code : " + this.init_up_code);
        //alert("init_up_code : " + this.init_up_code);
        /*
        setTimeout( function() { 
            this.fetchUpline();        
        }, 1000);
        */
      } else {
        this.member.up_code = "";
        this.member.up_name = "";
      }
      this.member.password = "";
      this.member.epoint_password = "";
      this.member.email = "";
      this.member.mobile = "";
      this.member.telephone = "";
      this.member.fortune = "";
      this.member.acc_no = "";
      this.member.acc_name = "";
      this.member.birthday = "01-01-2530";
      this.valid_username = false;
      this.valid_name_t = false;
      this.valid_id_card = false;
      this.valid_upline = false;
      this.valid_sponsor = false;
      this.readonly = false;
      this.input_editable_color = "yellow-1";
      this.side_toggle_color = "primary";
      this.insert_btn_panel = "";
      this.add_btn_panel = "hidden";
      this.current_mcode = "";
      this.member.mdate = this.session_date;
      this.bonusrecList = this.new_bonusrecList;
      this.password_visible = "";
      this.epoint_password_visible = "";
      this.setGenderSelected(this.member.sex);
      this.setMstatusSelected(this.member.mstatus);
      this.member.idprovince = "กรุงเทพฯ";
      this.setIDProvinceSelected(this.member.idprovince);
      this.member.province = "กรุงเทพฯ";
      this.setProvinceSelected(this.member.province);
      this.setBonusRecSelected(this.member.bonusrec);
      this.setBankSelected(this.member.bankcode);
      this.$emit("mode", "edit");
      if (this.init_up_code_flag) {
        this.fetchUpline();
        this.init_up_code_flag = false;
      }
    },
    view_form () {
      this.readonly = true;
      this.insert_btn_panel = "hidden";
      this.add_btn_panel = "";
      this.password_visible = "hidden";
      this.epoint_password_visible = "hidden";
      this.current_mcode = this.mcode;
      this.fetchData();
      this.$emit("mode", "view");
      this.input_editable_color = "grey-2";
    },
    getSelectedBill (selected_item) {
      this.member = this.new_member;
      this.member.number = selected_item.number;
      this.member.prodcode = selected_item.prodcode;
      this.member.prodid = selected_item.prodid;
      this.init_form();
    },
    getSelectedCenter (selected_item) {
      this.member.scode = selected_item.scode;
      this.member.sname = selected_item.sname;
    },
    getSelectedSponsor (selected_item) {
      this.member.sp_code = selected_item.mcode;
      //this.member.sp_name = selected_item.name_t;
      this.fetchSponsor();
    },
    getSelectedUpline (selected_item) {
      this.member.up_code = selected_item.mcode;
      //this.member.up_name = selected_item.name_t;
      this.fetchUpline();
    },
    bill_dialog_hide () {
      this.bill_dialog_show = false;
    },
    scode_dialog_hide () {
      this.scode_dialog_show = false;
      //console.log("hide set scode_dialog_show = " + this.scode_dialog_show);
    },
    spcode_dialog_hide () {
      this.spcode_dialog_show = false;
      //console.log("hide set spcode_dialog_show = " + this.spcode_dialog_show);
    },
    upcode_dialog_hide () {
      this.upcode_dialog_show = false;
      //console.log("hide set upcode_dialog_show = " + this.upcode_dialog_show);
    },
    member_password_dialog_hide () {
      this.member_password_dialog_show = false;
      //console.log("hide set member_password_dialog_show = " + this.member_password_dialog_show);
    },
    name_e_clone_name_t () {
      this.member.name_e = this.member.name_t;
    },
    acc_name_clone_name_t () {
      this.member.acc_name = this.member.name_t;
    },
    address_clone_idaddress () {
      this.member.address1 = this.member.idaddress1;
      this.member.address2 = this.member.idaddress2;
      this.member.zip = this.member.idzip;
      this.province_selected = this.idprovince_selected;
      this.setProvinceSelected(this.idprovince_selected.label);
    },
    filterFnAutoselectBank (val, update) {
        // call abort() at any time if you can't retrieve data somehow
        setTimeout(() => {
          update(
            () => {
              if (val === '') {
                this.bankOptions = this.bankList
              }
              else {
                const needle = val.toLowerCase()
                this.bankOptions = this.bankList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                /*
                const result = members.filter((jsonOptions) => {
                  return jsonOptions.label > 25
                })
                this.options = result*/
              }
            },

            // next function is available in Quasar v1.7.4+;
            // "ref" is the Vue reference to the QSelect
            ref => {
              if (val !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
                ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
              }
            }
          )
        }, 300)
    },
    filterFnAutoselect (val, update) {
        // call abort() at any time if you can't retrieve data somehow
        setTimeout(() => {
          update(
            () => {
              if (val === '') {
                this.provinceOptions = this.provinceList
              }
              else {
                const needle = val.toLowerCase()
                this.provinceOptions = this.provinceList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                //console.log("filter result:" + this.provinceOptions[0].label);
                /*
                const result = members.filter((jsonOptions) => {
                  return jsonOptions.label > 25
                })
                this.options = result*/

              }
            },

            // next function is available in Quasar v1.7.4+;
            // "ref" is the Vue reference to the QSelect
            
            ref => {
              if (val !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
                ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
              }
            }
          )
        }, 300)
      },

    abortFilterFn () {
        // console.log('delayed filter aborted')
    },
      /*
      // input array contain some elements. 
      var array = [2, 7, 8, 9]; 
        
      // Here find function returns the value of  
      // the first element in the array that satisfies  
      // the provided testing function (return element > 4). 
      var found = array.find(function(element) { 
        return element > 4; 
      }); 
        
      // Printing desired values. 
      console.log(found); 
      */
    setBonusRecSelected(selected_value) {
      var found = this.all_bonusrecList.find(function(element) { 
        return element.value == selected_value; 
      });
      this.bonusrec_selected = found;
    },  
    setIDProvinceSelected(selected_value) {
      var found = this.provinceList.find(function(element) { 
        return element.label == selected_value; 
      }); 
      //console.log("gender result : " + JSON.stringify(found));
      this.idprovince_selected = found;
      //this.gender_selected.splice(0,1);
      //this.gender_selected.push(result);
    },     
    setProvinceSelected(selected_value) {
      //console.log("province selected_value:" + selected_value);
      var found = this.provinceList.find(function(element) { 
        return element.label == selected_value; 
      });
      this.province_selected = found;
    },    
    setBankSelected(selected_value) {
      var found = this.bankList.find(function(element) { 
        return element.value == selected_value; 
      });
      this.bank_selected = found;
    },  
    setGenderSelected(selected_value) {
      var found = this.genderList.find(function(element) { 
        return element.value == selected_value; 
      }); 
      this.gender_selected = found;
    },
    setMstatusSelected(selected_value) {
      var found = this.mstatusList.find(function(element) { 
        return element.value == selected_value; 
      }); 
      //console.log("gender result : " + JSON.stringify(found));
      this.mstatus_selected = found;
      //this.gender_selected.splice(0,1);
      //this.gender_selected.push(result);
    },
    getSelectedValue(selected_value) {
      this.mcode = selected_value;
      //console.log("Child Selected Value :" + selected_value);
      this.member.mcode = selected_value;
      //console.log("Parent member.mcode :" + this.member.mcode);
      //this.$refs.mcode.value = this.mcode;
    },
    /*
    editMode() {
      this.input_editable_color = this.editableColor;
      this.$emit("mode", "edit");
    },
   
    viewMode() {
      this.input_editable_color = "grey-1";
      this.$emit("mode", "view");
    },
     */
    fetchName: function () {
          //console.log("fetchName");
          let self = this;
          if (self.member.name_t.length > 3 && !self.readonly) {
              self.$q.loading.show();
              const params = new URLSearchParams();
              params.append("mcode", this.session_mcode);
              params.append("name_t", this.member.name_t);
              self.axios({
                method: "post",
                timeout: 60 * 4 * 1000, // Let's say you want to wait at least 4 mins
                url: self.url_api + "MemberClient/Member.Name_t.Check.aspx",
                data: params
              })
              .then(function (response) {
                  //console.log("response data");
                  let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
                  let check_name_t = parseInt(responseDoc.getElementsByTagName('check_name_t')[0].textContent);
                  if (check_name_t == 0) {
                    self.valid_name_t = true;
                  } else {
                    self.valid_name_t = false;
                    self.triggerNegative("ชื่อสมาชิกซ้ำ");
                  }
                  self.$q.loading.hide();
              })
              .catch(function (error) {
                  console.log("Error :" + error);
                  self.valid_name_t = false;
                  self.member.name_t = "";
                  self.triggerNegative("ชื่อสมาชิกไม่ถูกต้อง");
                  self.$q.loading.hide();
              });
              self.$q.loading.hide();
          } else {
            this.valid_name_t = false;
          }
    }, 
    fetchSponsor: function () {
          //console.log("fetchSponsor");
          if (this.member.sp_code.length < this.app_mcode_length) {
            this.member.sp_code = this.paddingCode(this.member.sp_code);
          }
          let self = this;
          if (self.member.sp_code.length == self.app_mcode_length && !self.readonly) {
              self.$q.loading.show();
              const params = new URLSearchParams();
              params.append("mcode", this.session_mcode);
              params.append("sp_code", this.member.sp_code);
              params.append("agent", this.session_agent);
              self.axios({
                method: "post",
                timeout: 0,
                url: self.url_api + "MemberClient/Member.GetSpcodeInfo.JSON.aspx",
                data: params
              })
              .then(function (response) {
                  //console.log("response data");
                  let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
                  let result_name = responseDoc.getElementsByTagName('name_t')[0].textContent;
                  if (result_name.length > 0) {
                    self.valid_sponsor = true;
                    self.member.sp_name = result_name;
                    self.triggerPositive("รหัสแนะนำถูกต้อง");
                    //self.fetchUpline();
                  } else {
                    self.valid_sponsor = false;
                    self.member.sp_name = "";
                    self.triggerNegative("รหัสแนะนำไม่ถูกต้อง");
                  }
                  /*
                  if (event.result.member.sp_code.toString() != "000000000") {
                    sp_code_txt.text = event.result.member.sp_code.toString();
                    sp_code_txt.editable = false;
                    sp_code_txt.styleName = "GeneralTextInputReadOnly";
                    Validate_SpCode();
                  } 
                  */
                  self.$q.loading.hide();
                  //console.log(self.member.birthday.substring(6,10) + "-" + self.member.birthday.substring(3,5) + "-" + self.member.birthday.substring(0,2))
              })
              .catch(function (error) {
                  console.log("Error :" + error);
                  self.valid_sponsor = false;
                  self.member.sp_name = "";
                  self.triggerNegative("รหัสแนะนำไม่ถูกต้อง");
                  self.$q.loading.hide();
              });
              self.$q.loading.hide();
          }
    }, 
    fetchUpline: function () {
          if (this.member.up_code.length < this.app_mcode_length) {
            this.member.up_code = this.paddingCode(this.member.up_code);
          }
          //alert("fetch upline");
          let self = this;
          if (self.member.up_code.length == self.app_mcode_length && !self.readonly) {
              self.$q.loading.show();
              const params = new URLSearchParams();
              params.append("mcode", this.session_mcode);
              params.append("up_code", this.member.up_code);
              params.append("side", this.member.side);
              params.append("agent", this.session_agent);
              self.axios({
                method: "post",
                timeout: 0,
                url: self.url_api + "MemberClient/Member.GetUpcodeInfo.JSON.aspx",
                data: params
              })
              .then(function (response) {
                  //console.log("response data");
                  let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
                  let result_side = responseDoc.getElementsByTagName('check_upcode')[0].textContent;
                  let result_name = responseDoc.getElementsByTagName('name_t')[0].textContent;
                  //console.log("result_side :" + result_side.toString());
                  if (result_name.length > 0 && result_side == "PASS") {
                    self.valid_upline = true;
                    self.member.up_name = result_name;
                    self.triggerPositive("รหัสอัพไลน์ถูกต้อง");
                  } else {
                    self.valid_upline = false;
                    self.member.up_name = "";
                    if (result_side == "OUTER")
                    {
                      self.triggerNegative("ข้ามสายงาน");
                      self.valid_upline = false;
                    }
                    
                    if (result_side == "FULL")
                    {
                      self.triggerNegative("สายงานไม่ว่าง");
                      self.valid_upline = false;
                    }
                  }
                  /*
                  if (event.result.member.sp_code.toString() != "000000000") {
                    sp_code_txt.text = event.result.member.sp_code.toString();
                    sp_code_txt.editable = false;
                    sp_code_txt.styleName = "GeneralTextInputReadOnly";
                    Validate_SpCode();
                  } 
                  */
                  self.$q.loading.hide();
                  //console.log(self.member.birthday.substring(6,10) + "-" + self.member.birthday.substring(3,5) + "-" + self.member.birthday.substring(0,2))
              })
              .catch(function (error) {
                  console.log("Error :" + error);
                  self.$q.loading.hide();
              });
              self.$q.loading.hide();
          }
    },    
    fetchIdCard: function () {
          let self = this;
          if (self.member.id_card.length == 13 && !self.readonly) {
              self.$q.loading.show();
              const params = new URLSearchParams();
              params.append("idcard", this.member.id_card);

              self.axios({
                method: "post",
                timeout: 0,
                url: self.url_api + "MemberClient/Member.IDCard.Check.aspx",
                data: params
              })
              .then(function (response) {
                  //console.log("response data");
                  let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
                  let count_idcard = parseInt(responseDoc.getElementsByTagName('check_idcard')[0].textContent);
                  //console.log("count_idcard :" + count_idcard.toString());
                  if (count_idcard == 0) {
                    self.valid_id_card = true;
                  } else {
                    self.valid_id_card = false;
                  }
                  let sp_code = responseDoc.getElementsByTagName('sp_code')[0].textContent;
                  if (sp_code != "000000000") {
                    self.member.sp_code = sp_code;
                    self.fetchSponsor();
                  }
                  /*
                  if (event.result.member.sp_code.toString() != "000000000") {
                    sp_code_txt.text = event.result.member.sp_code.toString();
                    sp_code_txt.editable = false;
                    sp_code_txt.styleName = "GeneralTextInputReadOnly";
                    Validate_SpCode();
                  } 
                  */
                  self.$q.loading.hide();
                  //console.log(self.member.birthday.substring(6,10) + "-" + self.member.birthday.substring(3,5) + "-" + self.member.birthday.substring(0,2))
              })
              .catch(function (error) {
                  console.log("Error :" + error);
                  self.valid_id_card = false;
                  self.$q.loading.hide();
              });
              self.$q.loading.hide();
          } else {
            self.valid_id_card = false;
          }
    },
    fetchUsername: function () {
          let self = this;
          if (self.member.name_f.length >= 4 && !self.readonly) {
              self.$q.loading.show();
              const params = new URLSearchParams();
              params.append("mcode", this.current_mcode);
              params.append("name_f", this.member.name_f);

              //console.log("process fetch data mcode:"+ this.current_mcode);
              self.axios({
                method: "post",
                timeout: 0,
                url: self.url_api + "MemberClient/Member.Name_f.Check.aspx",
                data: params
              })
              .then(function (response) {
                  //console.log("response data");
                  let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
                  let count_username = parseInt(responseDoc.getElementsByTagName('check_name_f')[0].textContent);
                  //alert("count_username :" + count_username.toString());
                  if (count_username == 0) {
                    self.valid_username = true;
                  } else {
                    self.valid_username = false;
                  }
                  self.$q.loading.hide();
                  //console.log(self.member.birthday.substring(6,10) + "-" + self.member.birthday.substring(3,5) + "-" + self.member.birthday.substring(0,2))
              })
              .catch(function (error) {
                  console.log("Error :" + error);
                  self.$q.loading.hide();
                  self.valid_username = false;
              });
              self.$q.loading.hide();
          } else {
             self.valid_username = false;
          }
    },
    fetchData: function () {
        //alert("register fetch data mcode:" + this.current_mcode);
        if (this.current_mcode != "") {
            let self = this;
            self.$q.loading.show();
            const params = new URLSearchParams();
            params.append("mcode", this.current_mcode);
            params.append("session_mcode", this.session_mcode);
            self.axios({
              method: "post",
              headers: {
                'Authorization': self.jwt_token
              },
              timeout: 0,
              url: self.url_api + "MemberClient/Member.View.JSON.aspx",
              data: params
            })
            .then(function (response) {
                //console.log("response data");
                //console.log(response);
                self.member = response.data[0];
                //alert("birthday:" + self.member.birthday);
                //alert(self.member.acc_no);
                self.valid_username = true;
                self.setGenderSelected(self.member.sex);
                self.setMstatusSelected(self.member.mstatus);
                self.setIDProvinceSelected(self.member.idprovince);
                self.setProvinceSelected(self.member.province);
                self.setBankSelected(self.member.bankcode);
                self.setBonusRecSelected(self.member.bonusrec);
                /*
                alert("self.new_registration :" + self.new_registration);
                alert("self.init_up_code :" + self.init_up_code);
                alert("self.app_mcode_length :" + self.app_mcode_length);
                */
                if (self.init_up_code.length == self.app_mcode_length && self.new_registration == false) {
              self.popupBillDialog();
                }
                self.$q.loading.hide();
            })
            .catch(function (error) {
                console.log("Error :" + error);
                self.$q.loading.hide();
            });
        } else {
              let self = this;
              if (self.init_up_code.length == self.app_mcode_length && self.new_registration == false) {
                  self.popupBillDialog();
              }
        }	
    },
    fetchBank: function () {
      let self = this;
      self.axios({
      method: "post",
        url: self.url_api + "MemberClient/Bank.ListAll.JSON.aspx"
      })
      .then(function (response) {
        //console.log("bank result :" + response.data[0].label);
        self.bankList = response.data;
        self.fetchData();
      })
      .catch(function (error) {
        console.log("Error :" + error);
      });
    }
  },
  computed: {
      url_api: get("URL_API_PATH"),
      jwt_token: get('JWT_TOKEN'),
      editableColor: get("EDITABLE_COLOR"),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_date: get('SESSION_DATE'),
      app_mcode_length: get('APP_MCODE_LENGTH'),
      session_agent: get('SESSION_AGENT'),
      session_scode: get('SESSION_SCODE'),
      provinceList: get('PROVINCE_LIST'),

      compute_valid_email() {
        if (this.member.email.trim().length > 0 && !this.readonly) {
          
          if (this.email_valid) {
             return true;
          } else {
             return false;
          }
          
          //return true;
        } else {
          return false;
        }
      },
      compute_valid_mobile_no() {
        if (this.member.mobile.trim().length == 10 && !this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      compute_valid_bank() {
        if (this.bank_isValid && !this.readonly && this.bank_selected.value.length > 0) {
          return true;
        } else {
          return false;
        }
      },
      compute_valid_acc_no() {
        if (this.acc_no.length == 13 && !this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      compute_valid_acc_name() {
        if (this.member.acc_name.trim().length > 1 && !this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      compute_valid_sponsor() {
        if (this.valid_sponsor && !this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      compute_valid_upline() {
        if (this.valid_upline && !this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      compute_valid_name_t() {
        if (this.valid_name_t && !this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      compute_valid_id_card() {
        if (this.valid_id_card && !this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      compute_valid_username() {
        if (this.valid_username && !this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      email_isValid() {
        if (this.email_valid && !this.readonly) {
          //alert("email valid");
          return true;
        } else {
          if (this.member.email.length == 0 || this.readonly) {
            return true;
          } else {
             //alert("email invalid");
            return false;
          }
        }
      },
      mobile_no_isValid() {
        if (this.member.mobile.length >= 10 || this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      upcode_isValid() {
        if (this.member.up_code.length == this.app_mcode_length && this.valid_upline || this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      spcode_isValid() {
        if (this.member.sp_code.length == this.app_mcode_length && this.valid_sponsor || this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      name_t_isValid() {
        if (this.member.name_t.length > 3 && this.valid_name_t || this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      id_card_isValid() {
        if (this.member.id_card.length == 13 && this.valid_id_card || this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      username_isValid() {
        if (this.member.name_f.length >= 4 && this.valid_username || this.readonly) {
          return true;
        } else {
          return false;
        }
      },
      password_isValid() {
        if (this.member.password.length > 0) {
          return true;
        } else {
          return false;
        }
      },
      bank_acc_no_isValid() {
        let acc_no = this.replaceAll(this.replaceAll(this.member.acc_no,"#",""),"-","");
        let acc_name = this.member.acc_name.trim();
        //if ((this.bonusrec_selected.value == "4") || this.readonly)  {
        if (( this.bonusrec_selected.value == "4" && acc_no.length == 0 && acc_name.length == 0) || this.readonly) {
          return true;
        } else {
          //console.log("bank_acc_no_isValid : " + acc_no);
          if (acc_no.length == 10) {
            return true;
          } else {
            return false;
          }
        }
      },
      bank_acc_name_isValid() {
        let acc_no = this.replaceAll(this.replaceAll(this.member.acc_no,"#",""),"-","");
        let acc_name = this.member.acc_name.trim();
        if ((this.bonusrec_selected.value == "4" && acc_no.length == 0 && acc_name.length == 0) || this.readonly)  {
          return true;
        } else {
          if (acc_name.length > 1) {
            return true;
          } else {
            return false;
          }
        }
      },
      bank_isValid() {
        let acc_no = this.replaceAll(this.replaceAll(this.member.acc_no,"#",""),"-","");
        let acc_name = this.member.acc_name.trim();
        if ((this.bonusrec_selected.value == "4" && acc_no.length == 0 && acc_name.length == 0) || this.readonly)  {
          return true;
        } else {
          if (this.bank_selected.value != "") {
            return true;
          } else {
            return false;
          }
        }
      },
      epoint_password_isValid() {
        if (this.member.epoint_password.length > 0) {
          return true;
        } else {
          return false;
        }
      },
      regis_branch: function() {
        return this.member.rcode + " - " + this.member.branch_name;
      },
      member_age: function() {
        try {
          if (this.member.birthday.length == 10) {
            let ad_year = parseInt(this.member.birthday.substring(6,10)) - 543;
            let date1 = Date.now();
            let date2 = new Date(ad_year, this.member.birthday.substring(3,5), this.member.birthday.substring(0,2));
            let unit = "years"
            let member_age_value = date.getDateDiff(date1, date2, unit)
            return member_age_value;
          } else {
            return 0;
          }
        }
        catch(err) {
          return 0;
        }
      }
  },
  watch: {
    mcode: function () {
      //alert("watch fetchData mcode:" + this.mcode);
      //if (this.new_registration == true) {
        //alert("fetchData mcode:" + this.current_mcode);
        this.current_mcode = this.mcode;
        this.fetchData();
      //}
    },
    "member.acc_no": function() {
      this.acc_no = this.replaceAll(this.member.acc_no,"#","");
      //console.log("acc_no = " + this.acc_no);
    },
    "member.sp_code": function() {
      if (this.member.sp_code.length < this.app_mcode_length) {
        this.member.sp_name = "";
        this.valid_sponsor = false;
      }
    },
    "member.up_code": function() {
      if (this.member.up_code.length < this.app_mcode_length) {
        this.member.up_name = "";
        this.valid_upline = false;
      }
    },
    "member.side": function() {
      if (!this.readonly && this.new_registration == true) {
        this.fetchUpline();
      }
    },
    "member.password": function() {
      let pwd = this.member.password;
      //pwd.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/);
      let pwd_match = pwd.match(/^(?=.*?[a-z])(?=.*?[0-9]).*$/);
      if (pwd_match == this.member.password && this.member.password.length >= 6) {
          this.valid_password = true;
      } else {
          this.valid_password = false;
          if (!this.readonly) {
            this.$q.notify({
              type: 'negative',
              classes: "app-font",
              message: "รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร และประกอบด้วยตัวเลข [0-9] และตัวอักษร [a-z]"
            });
          }
      }
    },
    "member.epoint_password": function() {
      let pwd = this.member.epoint_password;
      //pwd.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/);
      let pwd_match = pwd.match(/^(?=.*?[a-z])(?=.*?[0-9]).*$/);
      if (pwd_match == this.member.epoint_password && this.member.epoint_password.length >= 6) {
          this.valid_epoint_password = true;
      } else {
          this.valid_epoint_password = false;
          if (!this.readonly) {
            this.$q.notify({
              type: 'negative',
              classes: "app-font",
              message: "รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร และประกอบด้วยตัวเลข [0-9] และตัวอักษร [a-z]"
            });
          }
      }
    }
    
  },
  mounted:function () {
    setTimeout(() => {
      this.current_mcode = this.mcode;
      //alert(this.current_mcode)
      this.fetchBank();
    }, 3000);
   
    /*
    let self = this;
    if (self.init_up_code.length == self.app_mcode_length) {
        setTimeout( function() {
          self.$q.loading.hide();
          self.popupBillDialog();
        }, 1000);
    } else {
        self.$q.loading.hide();
    }
    */
  },
  created () {
      //console.log('Component created.');
      this.$q.loading.show();
  }    
}       
