//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'

export default {
  name: "QrCode30Dialog",
  props: ["show_status","number"],
  comcomponents: {
  },
  data () {
    return {
      fixed: false,
      loading: false,
      trans_no: "",
      trans_date: "",
      ref1: "",
      amount: "",
      qrImage: "",
    }
  },
  methods: {
      triggerPositive (msg_text) {
          this.$q.notify({
            type: 'positive',
            progress: true,
            message: msg_text,
            classes:'app-font'
          });
      },
      triggerNegative (msg_text) {
        this.$q.notify({
          type: 'negative',
          message: msg_text,
          classes:'app-font'
        })
      },
      onHide() {
        //console.log("onHide");
        this.$emit("onHide", "");
        //this.$parent.scode_dialog_show = false;
      },
      onShow() {
        this.$q.loading.show();
        this.getData(); 
      },
      validate_transaction: function () {
        let self = this;
        self.$q.loading.show();
        const params = new URLSearchParams();
        params.append('trans_no', self.trans_no);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },  
          timeout: 0,
          url: self.url_api + 'MemberClient/ePoint.Orders.Payment.QR30.TQRC.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let paid_status = responseDoc.getElementsByTagName('status_code')[0].textContent;
            console.log("paid_status : " + paid_status);
            if (paid_status == "1000") {
                self.triggerPositive("ได้รับชำระเงินเรียบร้อยแล้ว");
                self.$q.loading.hide();
                self.$emit("onPaid","");
                self.fixed = false;
            } else {
                self.triggerNegative("ไม่พบรายการชำระเงิน!");
                self.$q.loading.hide();
                self.fixed = false;
            }
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.loading = false;
            self.$q.loading.hide();
        });
      },
      getData: function () {
        var self = this;
        self.$q.loading.show();
        self.loading = true;
        const params = new URLSearchParams();
        params.append('number', self.number);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },  
          timeout: 0,
          url: self.url_api + 'MemberClient/ePoint.Orders.Payment.QR30.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            self.trans_no = responseDoc.getElementsByTagName('trans_no')[0].textContent;
            self.trans_date = responseDoc.getElementsByTagName('trans_date')[0].textContent;
            self.ref1 = responseDoc.getElementsByTagName('ref1')[0].textContent;
            self.amount = responseDoc.getElementsByTagName('amount')[0].textContent;
            self.qrImage = "data:image/png;base64," + responseDoc.getElementsByTagName('qrImage')[0].textContent;
            //alert("trans_no : " + self.trans_no);
            console.log("trans_no : " +self.trans_no);
            //console.log("qrImage : " + self.qrImage);
            self.loading = false;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.loading = false;
            self.$q.loading.hide();
        });
      }
    /*
    ,
    // when props.ok() gets called
    onOk (data) { },

    // when props.cancel() gets called
    onCancel () { },

    // when we show it to the user
    onShow () { },

    // when it gets hidden
    onHide () { },

    // custom handler
    async choose (okFn, hero) {
      if (this.name.length === 0) {
        this.$q.dialog({
          title: 'Please specify your name!',
          message: `Can't buy tickets without knowing your name.`
        })
      }
      else {
        await okFn()
        this.$q.notify(`Ok ${this.name}, going with ${hero}`)
      }
    }
    */
  },
  mounted () {
      //let self = this;
      //console.log('EStoreOrdersDetail Dialog mounted.');
      /*
      this.$q.loading.show();
      this.mcode_current = this.mcode_start;
      this.getData();
      
      if (this.mcode_start != "") {
        this.mcode_current = this.mcode_start;
        this.$q.loading.show();
        this.getData();
      }*/
      /*
      window.addEventListener("keyup", e => {
        switch (e.keyCode) {
          case 38:
            //alert("up" + e.keyCode + "current row:" + this.current_row.toString());
            //alert(parseInt(this.current_row)-1);
            if (parseInt(this.current_row) > parseInt(this.min_row)) {
              alert(parseInt(this.current_row)-1);
              this.current_row = parseInt(this.current_row) - 1
              this.selected.splice(0,1);
              this.selected.push(this.datalist[parseInt(this.current_row)-1]);
            }
            break;
          case 40:
            //alert("down" + e.keyCode + "current row:" + this.current_row.toString());
            //alert(parseInt(this.current_row)+1);
            if (parseInt(this.current_row) < parseInt(this.max_row)) {
              alert(parseInt(this.current_row)+1);
              this.current_row = parseInt(this.current_row) + 1;
              this.selected.splice(0,1);
              this.selected.push(this.datalist[parseInt(this.current_row)-1]);
            }
            break;
        }
      });
      */
      //this.writeConsole();

      /*
      const self = this;
      console.log('component API URL:' + self.url_api + 'Member/Member.View.JSON.aspx');
ss
      */
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
    }
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
  },
  created () {
      //console.log('Component created.');
  }
}
