//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'

export default {
  name: "EPointTransferDialog",
  props: ["show_status","item_info"],

  data () {
    return {
      full_width_boolean: false,
      dialog_style: "",
      submit_btn_disable: false,
      isPwd: true,
      fixed: false,
      loading: false,
      quantity: 0,
      total_pv: 0,
      total_amount: 0
    }
  },
  methods: {
      setDialogSize() {
        //if (this.$q.screen.width > 1023) { 
        if (this.$q.screen.width > 800) {
          console.log('screen large');
          this.full_width_boolean = false;
          this.dialog_style = "background-color:#f5fafe; width: 480px; max-width: 40vw;";
        }
        else {
          console.log('screen small');
          this.full_width_boolean = true;
          this.dialog_style = "height:270px;";
        }
      },
      formatnumber(num, decimal) {
        return num.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      },
      triggerPositive (msg_text) {
        this.$q.notify({
          type: 'positive',
          progress: true,
          message: msg_text,
          classes:'app-font'
        });
      },
      triggerNegative (msg_text) {
        this.$q.notify({
          type: 'negative',
          message: msg_text,
          classes:'app-font'
        })
      },
      clearForm: function() {
        this.quantity = 0;
        //this.fetchEPointBalance();
      },
      onHide() {
        //console.log("onHide");
        this.quantity = 0;
        this.$emit("onHide", "");
      },
      onShow() {
        this.$refs.qty_input.focus();
      },
      fetchEPointBalance: function () {
        let self = this;
        const params = new URLSearchParams();
        params.append('mcode', self.session_mcode);
        self.axios({
          method: "post",
          url: self.url_api + "MemberClient/ePoint.GetBalance.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          self.epoint_balance = responseDoc.getElementsByTagName('epoint')[0].textContent;
          //console.log("epoint_balance : " + self.epoint_balance);
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      postData: function () {
        var self = this;

        if (self.quantity == 0) {
          return
        }

        self.submit_btn_disable = true;

        self.loading = true;
        const params = new URLSearchParams();
      
        params.append('number', self.item_info.cart_no);
        params.append('bill_date', self.session_date);
        params.append('prodcode', self.item_info.prodcode);
        params.append('quantity', self.quantity);

        self.axios({
          method: 'post',
          timeout: 0,
          url: self.url_api + 'MemberClient/eStore.Billing.Temp.InsertItem.aspx',
          data: params
        })
        .then(function (response) {
            //console.log(response);
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let result = responseDoc.getElementsByTagName('status')[0].textContent;
            let result_txt = responseDoc.getElementsByTagName('message')[0].textContent;
            if (result == "1") {
              self.triggerPositive(result_txt);
              self.clearForm();
              self.fixed = false;
            } else {
              self.triggerNegative(result_txt);
            }
            self.loading = false;
            self.$q.loading.hide();
            self.submit_btn_disable = false;
            
            if (result == "1") {
              self.$emit("onAddNewItem", "");
            }
            
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.triggerNegative("ไม่สามารถบันทึกข้อมูลได้");
            self.loading = false;
            self.$q.loading.hide();
            self.submit_btn_disable = false;
        });
       
      }
  },
  mounted () {
      //console.log('EStoreAddToCard Dialog mounted.');
  },
  watch: {
    show_status: function () {
      //console.log("fixed :" + this.fixed);
      this.fixed = this.show_status;
    },
    "$q.screen.width"() {
      this.setDialogSize();
    },
    quantity: function() {
      this.total_pv = this.formatnumber(parseFloat(this.item_info.pv.replace(",","")) * parseInt(this.quantity),2);
      this.total_amount = this.formatnumber(parseFloat(this.item_info.price.replace(",","")) * parseInt(this.quantity),2);
    }
  },
  computed: {
      bank_info: function () {
        return this.bank_code + " - "  + this.bank_name;
      },
      url_api: get('URL_API_PATH'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_date: get('SESSION_DATE')
  },
  created () {
      //console.log('Component created.');
      this.setDialogSize()
      //this.mcode_current = this.mcode_start;
      //this.fetchPosition();
  }    
 
}
