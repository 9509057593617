//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'vuex-pathify'
import store from '../store/index'
import MemberPasswordDialog from '@/components/MemberPasswordDialog.vue'
import EpointPasswordDialog from '@/components/EpointPasswordDialog.vue'

export default {
  components: {
    MemberPasswordDialog,
    EpointPasswordDialog
  },
  data () {
    return {
      member_password_dialog_show: false,
      epoint_password_dialog_show: false,
      mta_expire: false,
      mta_expire_txt: "",
      mta_expire_dd: "",
      mta_expire_mon: "",
      mta_expire_yyyy: "",

      topup_expire: false,
      topup_expire_txt: "",
      topup_expire_dd: "",
      topup_expire_mon: "",
      topup_expire_yyyy: "",

      username: "",
      name_t: "",
      mdate: "",
      sp_code: "",
      sp_name: "",
      up_code: "",
      up_name: "",
      side: "",
      position: "",
      sposition: "",
      ppvtotal: "",
      pv_l: "",
      pv_r: "",
      epoint: ""
    }
  },
  methods: {
      member_password_dialog_hide () {
        this.member_password_dialog_show = false;
      },
      epoint_password_dialog_hide () {
        this.epoint_password_dialog_show = false;
      },
      fetchData: function () {
        let self = this;
        const params = new URLSearchParams();
        params.append('mcode', self.session_mcode);
        self.axios({
          method: "post",
          headers: {
            'Authorization': self.jwt_token
          },
          url: self.url_api + "MemberClient/main_page.JSON.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');

          self.mta_expire_dd = responseDoc.getElementsByTagName('mta_expire_dd')[0].textContent;
          self.mta_expire_mon = responseDoc.getElementsByTagName('mta_expire_mon')[0].textContent;
          self.mta_expire_yyyy = responseDoc.getElementsByTagName('mta_expire_yyyy')[0].textContent;
          self.mta_expire_txt = responseDoc.getElementsByTagName('mta_expire_txt')[0].textContent;
          if (self.mta_expire_txt.length > 0) { self.mta_expire = true } else { self.mta_expire = false }

          self.topup_expire_dd = responseDoc.getElementsByTagName('topup_expire_dd')[0].textContent;
          self.topup_expire_mon = responseDoc.getElementsByTagName('topup_expire_mon')[0].textContent;
          self.topup_expire_yyyy = responseDoc.getElementsByTagName('topup_expire_yyyy')[0].textContent;
          self.topup_expire_txt = responseDoc.getElementsByTagName('topup_expire_txt')[0].textContent;
          if (self.topup_expire_txt.length > 0) { self.topup_expire = true } else { self.topup_expire = false }

          self.username = responseDoc.getElementsByTagName('name_f')[0].textContent;
          self.name_t = responseDoc.getElementsByTagName('name_t')[0].textContent;
          self.mdate = responseDoc.getElementsByTagName('mdate')[0].textContent;
          /*
          self.sp_code = responseDoc.getElementsByTagName('sp_code')[0].textContent;
          self.sp_name = responseDoc.getElementsByTagName('sp_name')[0].textContent;
          self.up_code = responseDoc.getElementsByTagName('up_code')[0].textContent;
          self.up_name = responseDoc.getElementsByTagName('up_name')[0].textContent;
          self.side = responseDoc.getElementsByTagName('side')[0].textContent;
          */
          self.position = responseDoc.getElementsByTagName('position')[0].textContent;
          self.sposition = responseDoc.getElementsByTagName('sposition')[0].textContent;
          self.epoint = responseDoc.getElementsByTagName('epoint')[0].textContent;
          self.ppvtotal = responseDoc.getElementsByTagName('ppvtotal')[0].textContent;
          self.pv_l = responseDoc.getElementsByTagName('pv_l')[0].textContent;
          self.pv_r = responseDoc.getElementsByTagName('pv_r')[0].textContent;
          self.$q.loading.hide();
        })
        .catch(function (error) {
          console.log("Error :" + error);
          self.$q.loading.hide();
        });
      },
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
  },
  mounted: function () {
    this.$q.loading.show();
    //this.fetchData();
    let self = this;
    setTimeout( function() { 
        self.fetchData();
    }, 300);
  },
  created () {
      //console.log('Component created.');
      store.set("APP_TOOLBAR_TITLE","หน้าหลัก");
      //this.fetchData();
      //this.mcode_current = this.mcode_start;
      //this.fetchPosition();
  }    
}
