//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { openURL } from 'quasar'
import { get } from 'vuex-pathify'
import store from '../store/index'
import MbscDatePicker from '@/components/MbscDatePicker.vue'
import EStoreOrdersDetailDialog from '@/components/EStoreOrdersDetailDialog.vue'

export default {
  components: {
    MbscDatePicker,
    EStoreOrdersDetailDialog
  },
  data () {
    return {
      orders_no:"",
      epoint_orders_detail_dialog_show: false,
      searchText:"",
      from_date:"",
      to_date:"",
      loading: false,
      query:"1",
      total_record: 0,
      total_page: 1,
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'number', align: 'center', label: 'เลขที่เอกสาร', field: 'number'},
        { name: 'for_mcode', align: 'center', label: 'รหัสสมาชิก', field: 'for_mcode'},
        { name: 'type', align: 'center', label: 'ประเภท', field: 'type'},
        { name: 'date', align: 'center', label: 'วันที่', field: 'date'},
        { name: 'bill_status', align: 'center', label: 'สถานะ', field: 'bill_status'},
        { name: 'approved_date', align: 'center', label: 'วันที่จัดส่ง', field: 'approved_date' },
        { name: 'received_date', align: 'center', label: 'วันที่รับของ', field: 'received_date' },
        { name: 'total_pv', align: 'right', label: 'รวม PV', field: 'total_pv' },
        { name: 'total_ba', align: 'right', label: 'eBonus', field: 'total_ba' }
      ],
    }
  },
  methods: {
      resetData: function () {
        this.from_date = this.session_mdate;
        this.to_date = this.session_date;
        this.searchText = "";
        this.getData();
      },
      openDetailDialog: function(number) {
        this.orders_no = number;
        this.epoint_orders_detail_dialog_show = true;
      },
      epoint_orders_detail_dialog_hide () {
        this.epoint_orders_detail_dialog_show = false;
        //console.log("hide set epoint_orders_detail_dialog_show = " + this.epoint_orders_detail_dialog_show);
      },
      exportPDF: function(number) {
          let self = this;
          let actionUrl = self.url_api + "../pdf/eStore.Orders.Print.PDF.aspx";
          let windowName = "";
          let windowFeatures = "";
          let data = {
                        Authorization: self.jwt_token,
                        number: number
                     };
          this.windowOpenWithPost(actionUrl, windowName, windowFeatures, data);
      },
      openDeliverInfo: function( deliver_code, deliver_url ) {
        openURL(deliver_url + deliver_code);
      },
      /*
      exportExcel: function() {
          let self = this;
          openURL(self.url_api + 'Bonus/Report.Daily.Bonus.ByPeriodDate.Excel.aspx')    
      },*/
      requestRowClick: function(selected_mcode, selected_row) {
          this.current_row = selected_row.rownum;
          //console.log("row click :" + selected_mcode);
          if (this.selected.mcode !== selected_mcode) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
          }
      },
      /*
      fetchBonusDate: function () {
        //console.log("fetch bonus date");
        const params = new URLSearchParams();
        params.append("mcode", this.session_mcode);
        let self = this;
        self.axios({
          method: "post",
          url: self.url_api + "MemberClient/GetBonusDateRange.aspx",
          data: params
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          let start_date = responseDoc.getElementsByTagName('startdate')[0].textContent;
          //let end_date = responseDoc.getElementsByTagName('enddate')[0].textContent;
          //console.log("start_date :" + start_date + " end_date : " + end_date);
          self.from_date = start_date;
          self.to_date = self.session_date;
        })
        .catch(function (error) {
          console.log("Error :" + error);
        });
      },
      */
      updateDate(e, title) {
        //console.log("update mdate to :" + e);
        switch (title) {
          case "ตั้งแต่วันที่":
            this.from_date = e;
            break;
          case "ถึงวันที่":
            this.to_date = e;
            break;  
        }
      },
      getData: function () {
        var self = this;
        self.loading = true;
        self.rowsPerFetch = self.appRowsPerFetch;
        const params = new URLSearchParams();
        params.append('mcode', self.session_mcode);
        params.append('pagesize', self.rowsPerFetch);
        params.append('pageid', self.pageID);
        params.append('from_date', self.from_date);
        params.append('to_date', self.to_date);
        params.append('findword', self.searchText);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 0,
          url: self.url_api + 'MemberClient/eStore.GetBillHistory.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
            
            /*
            console.log(responseDoc.getElementsByTagName('json')[0].textContent);
            console.log("record count " + record_count.toString());
            console.log("page count " + responseDoc.getElementsByTagName('pagecount')[0].textContent);
            */

            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
            } else {
              self.datalist = [];
              self.selected = [];
            }
            self.total_record = record_count;
            self.total_page = page_count;
            self.loading = false;
            self.$q.loading.hide();
        })
        .catch(function (error) {
            console.log("Error :" + error);
            self.datalist = [];
            self.selected = [];
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  },
  mounted: function () {
      /*
      window.addEventListener("keyup", e => {
        switch (e.keyCode) {
          case 38:
            alert(e.keyCode);
            break;
          case 40:
            alert(e.keyCode);
            break;
        }    
        /*
        if (String.fromCharCode(e.keyCode)=="&") {

        } else {
            if (String.fromCharCode(e.keyCode)=="(") {

            }
        }
      });*/
  
      //var self = this;
      //console.log('API URL:' + self.url_api + 'Member/Member.View.JSON.aspx');
      //console.log('Component mounted.');
      this.$q.loading.show();
      let self = this;
      setTimeout( function() { 
        self.getData();
      }, 1000);
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN'),
      session_date: get('SESSION_DATE'),
      session_mdate: get('SESSION_MDATE'),
  },
  watch: {
    pageID: function () {
      this.getData();
    },
    from_date: function () {
      this.getData();
    },
    to_date: function () {
      this.getData();
    }
  },
  created () {
      //console.log('Component created.');
      store.set("APP_TOOLBAR_TITLE","ประวัติการสั่งซื้อสินค้า");
      this.from_date = this.session_mdate;
      this.to_date = this.session_date;
      //this.fetchBonusDate();
  }    
}   
