//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


//import { openURL } from 'quasar'
import { get } from 'vuex-pathify'
import store from '../store/index'

export default {
  data () {
    return {
      init: false,
      submit_btn_disable: true,
      loading: false,
      query:"1",
      total_record: 0,
      total_page: 1,
      rowsPerFetch: 100,
      pagination: {
        rowsPerPage: 0
      },
      pageID: 1,
      model: {label: "", value: ""},
      roundList: [],
      roundOptions:this.roundList,
      from_round_selected: {label: "", value: "" },
      to_round_selected: {label: "", value: "" },
      data_key: 'rownum',
      dense: false,
      datalist: [],
      selected: [],
      columns: [
        { name: 'rownum', align: 'center', label: 'ลำดับ', field: 'rownum'},
        { name: 'round', align: 'center', label: 'รอบจำหน่าย', field: 'round'},
        { name: 'descript', align: 'left', label: 'รายละเอียด', field: 'descript'},
        { name: 'bonus_01', align: 'right', label: 'Matching', field: 'bonus_01' },
        { name: 'bonus_02', align: 'right', label: 'บริหารธุรกิจ', field: 'bonus_02' },
        { name: 'total_bonus', align: 'right', label: 'รวมโบนัส', field: 'total_bonus' },
        { name: 'tax_bonus', align: 'right', label: 'ค่าบริการ', field: 'tax_bonus'},
        { name: 'total_bonus_net', align: 'right', label: 'รวมสุทธิ', field: 'total_bonus_net'}
      ],
    }
  },
  methods: {
      setRoundmoSelected() {
        let i = this.roundList.length - 1;
        this.from_round_selected = this.roundList[i];
        this.to_round_selected = this.roundList[0];
        this.getData();
      },
      exportPDF: function(round, descript) {
          let self = this;
          let actionUrl = self.url_api + "../pdf/Report.Monthly.Bonus.Detail.PDF.aspx";
          let windowName = "";
          let windowFeatures = "";
          let data = {
                        Authorization: self.jwt_token,
                        round: round,
                        des: descript
                     };
          this.windowOpenWithPost(actionUrl, windowName, windowFeatures, data);
      },
      requestRowClick: function(selected_mcode, selected_row) {
          this.current_row = selected_row.rownum;
          //console.log("row click :" + selected_mcode);
          if (this.selected.mcode !== selected_mcode) {
            this.selected.splice(0,1);
            this.selected.push(selected_row);
            //this.$emit("onSeleted", selected_row);
          }
      },
      fetchRoundmo: function () {
        //console.log("fetch bonus round");
        let self = this;
        self.axios({
          method: "post",
          url: self.url_api + "MemberClient/Roundmo.ListAll.JSON.aspx"
        })
        .then(function (response) {
          let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
          //let round = responseDoc.getElementsByTagName('round_selected')[0].textContent;
          let result = responseDoc.getElementsByTagName('result')[0].textContent;
          if (result.length > 0) {
            self.roundList = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
            self.setRoundmoSelected();
          }
        })
        .catch(function (error) {
          console.log("Roundmo List Error :" + error);
        });
      },
      filterFnAutoselect (val, update) {
        // call abort() at any time if you can't retrieve data somehow
        setTimeout(() => {
          update(
            () => {
             if (val === '') {
                this.roundOptions = this.roundList
              }
              else {
                const needle = val.toLowerCase()
                this.roundOptions = this.roundList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                //console.log("filter result:" + this.roundOptions[0].label);
                /*
                const result = members.filter((jsonOptions) => {
                  return jsonOptions.label > 25
                })
                this.options = result*/
              }
            },
            // next function is available in Quasar v1.7.4+;
            // "ref" is the Vue reference to the QSelect
            ref => {
              if (val !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
                ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
              }
            }
          )
        }, 300)
      },
      abortFilterFn () {
        // console.log('delayed filter aborted')
      },
      getData: function () {
        var self = this;
        if (self.init) {
          self.loading = true;
        }
        self.rowsPerFetch = self.appRowsPerFetch;
        const params = new URLSearchParams();
        params.append('mcode', self.session_mcode)
        params.append('pagesize', self.rowsPerFetch);
        params.append('pageid', self.pageID);
        params.append('from_round', self.from_round_selected.value);
        params.append('to_round', self.to_round_selected.value);
        self.axios({
          method: 'post',
          headers: {
            'Authorization': self.jwt_token
          },
          timeout: 0,
          url: self.url_api + 'MemberClient/GetBonusMonth.JSON.aspx',
          data: params
        })
        .then(function (response) {
            let responseDoc = new DOMParser().parseFromString(response.data, 'text/xml');
            let record_count = parseInt(responseDoc.getElementsByTagName('recordcount')[0].textContent);
            let page_count = parseInt(responseDoc.getElementsByTagName('pagecount')[0].textContent);
            if (record_count > 0) {
              self.datalist = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent);
              self.selected = JSON.parse(responseDoc.getElementsByTagName('json')[0].textContent).slice(0, 1);
              self.total_record = record_count;
              self.total_page = page_count;
              self.loading = false;
              self.init = true;
              self.$q.loading.hide();
            } else {
              self.datalist = [];
              self.selected = [];
              self.total_record = 0;
              self.total_page = 1;      
              self.loading = false;
              self.init = true;
              self.$q.loading.hide();    
            }
        })
        .catch(function (error) {
            console.log("Error :" + error);
           self.datalist = [];
            self.selected = [];
            self.total_record = 0;
            self.total_page = 1;               
            self.loading = false;
            self.$q.loading.hide();
        });
      }
  },
  mounted: function () {
      this.fetchRoundmo();
  },
  computed: {
      url_api: get('URL_API_PATH'),
      jwt_token: get('JWT_TOKEN'),
      mcode_length: get('APP_MCODE_LENGTH'),
      appRowsPerFetch: get('APP_ROW_PER_FETCH'),
      session_mcode: get('SESSION_MCODE_LOGIN')
  },
  watch: {
    pageID: function () {
      if (this.init) {
        this.getData();
      }
    },
    from_round_selected: function () {
      if (this.init) {
        this.getData();
      }
    },
    to_round_selected: function () {
      if (this.init) {
        this.getData();
      }
    }
  },
  created () {
      //console.log('Component created.');
      store.set("APP_TOOLBAR_TITLE","โบนัสรายเดือน");
      this.$q.loading.show();
  }    
}   
